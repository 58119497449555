import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompliantRatioService {

  constructor() { }

  public static calculateCompliantRatio(compliantCounter: number, notCompliantCounter: number): string {
      var compliantRatio: number = 100;
      if (compliantCounter + notCompliantCounter > 0) {
      compliantRatio = compliantCounter / (compliantCounter + notCompliantCounter) * 100;
      // Correct round issue
      if (compliantRatio == 100 && notCompliantCounter > 0) {
          compliantRatio = 99.99;
      }
      }
      return compliantRatio.toFixed(2) + '%';
  }
}
