import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/core/config.service";
import { ExportsInput } from "./exports.model";

@Injectable({
    providedIn: 'root'
})
export class ExportService {

    constructor(private httpClient: HttpClient, private configService: ConfigService) {
    }

    putExportsInput(exportsInput: ExportsInput) {
        let body = [
            {
                'type': exportsInput.exports_input_type,
                'name': exportsInput.exports_input_name,
                'exports': exportsInput.exports
            }
        ]
        return this.httpClient.put<ExportsInput[]>(
            `${this.configService.config.apiUrl}/exports-inputs`,
            body
        )
    }

    deleteExportsInput(exportsInput: ExportsInput) {
        let body = [
            {
                'type': exportsInput.exports_input_type,
                'name': exportsInput.exports_input_name
            }
        ]
        return this.httpClient.delete<ExportsInput[]>(
            `${this.configService.config.apiUrl}/exports-inputs`,
            {
                body: body
            }
        )
    }

    listExportsInputs() {
        return this.httpClient.get<ExportsInput[]>(
            `${this.configService.config.apiUrl}/exports-inputs`
        )
    }
}
