import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/core/config.service';
import { IChallengesResp } from './challenges.model';
import { map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChallengeService {

  constructor(private configService: ConfigService, private httpClient: HttpClient) { }

  getChallenges(){
    return this.httpClient.get<IChallengesResp>(`${this.configService.config.apiUrl}/list-challenges-data`)
  }
}
