import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { TranslateService } from '@ngx-translate/core';
import { ColorEnum } from '../../enum/color.enum';
import { v4 as uuidv4 } from 'uuid';

export type ChartComplianceTotalItem = {
  date: Date,
  compliantCount: number,
  notCompliantCount: number,
  total: number,
  delta: number,
  dateString: string
}

export type ChartComplianceTotalData = {
  values: ChartComplianceTotalItem[]
}

@Component({
  selector: 'app-chart-compliance-total',
  templateUrl: './chart-compliance-total.component.html',
  styleUrls: ['./chart-compliance-total.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComplianceTotalComponent implements AfterViewInit {

  @Input() public chartWidth: string = "100%";

  @Input() public chartHeight: string = "300px";

  private chart: any;

  public chartDivId = uuidv4();

  /**
   * Constructor
   */
  constructor(
    private translate: TranslateService
  ) {

  }

  /**
   * Clean up charts
   */
  public cleanUp() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  /**
   * Refresh the data
   */
  public refresh(data: ChartComplianceTotalData) {
    this.chart.data = data.values;
  }

  getChartStyle() {
    return "width: " + this.chartWidth + " ; height: " + this.chartHeight
  }

  ngAfterViewInit(): void {
    // Chart code goes in here
    am4core.useTheme(am4themes_animated);
    let seriesColor = am4core.color(ColorEnum.charts_default);

    this.chart = am4core.create(this.chartDivId, am4charts.XYChart);

    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.minZoomCount = 5;

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    let series = this.chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.openValueY = "total";
    series.dataFields.valueY = "compliantCount";
    series.tooltipText = this.translate.instant("CHARTS.FIELD_DATE") + " {dateString}\n" + this.translate.instant("CHARTS.FIELD_TOTAL") + " {total}\n" + this.translate.instant("CHARTS.FIELD_COMPLIANT") + " {compliantCount}\nNot Compliant: {notCompliantCount}";
    series.sequencedInterpolation = true;
    series.stroke = seriesColor;
    series.strokeWidth = 2;
    series.name = "Total";
    series.stroke = seriesColor;
    series.fill = series.stroke;
    series.fillOpacity = 0.8;

    let series2 = this.chart.series.push(new am4charts.LineSeries());
    series2.dataFields.dateX = "date";
    series2.dataFields.valueY = "total";
    series2.sequencedInterpolation = true;
    series2.strokeWidth = 2;
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.getStrokeFromObject = true;
    series2.tooltip.label.fill = am4core.color("#000");
    series2.name = "Compliant Count";
    series2.stroke = seriesColor;
    series2.fill = series2.stroke;

    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.xAxis = dateAxis;

    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    this.chart.scrollbarX = scrollbarX;

    // create ranges
    var negativeRange: any = undefined;

    // create ranges
    this.chart.events.on("datavalidated", function () {
      series.dataItems.each(function (s1DataItem: any) {
        let s1PreviousDataItem;
        let s2PreviousDataItem;

        let s2DataItem = series2.dataItems.getIndex(s1DataItem.index);

        if (s1DataItem.index > 0) {
          s1PreviousDataItem = series.dataItems.getIndex(s1DataItem.index - 1);
          s2PreviousDataItem = series2.dataItems.getIndex(s1DataItem.index - 1);
        }

        let startTime = am4core.time.round(new Date(s1DataItem.dateX.getTime()), dateAxis.baseInterval.timeUnit, dateAxis.baseInterval.count).getTime();

        // intersections
        if (s1PreviousDataItem && s2PreviousDataItem) {
          let x0 = am4core.time.round(new Date(s1PreviousDataItem.dateX.getTime()), dateAxis.baseInterval.timeUnit, dateAxis.baseInterval.count).getTime() + dateAxis.baseDuration / 2;
          let y01 = s1PreviousDataItem.valueY;
          let y02 = s2PreviousDataItem.valueY;

          let x1 = startTime + dateAxis.baseDuration / 2;
          let y11 = s1DataItem.valueY;
          let y12 = s2DataItem.valueY;

          let intersection = am4core.math.getLineIntersection({ x: x0, y: y01 }, { x: x1, y: y11 }, { x: x0, y: y02 }, { x: x1, y: y12 });

          startTime = Math.round(intersection.x);
        }

        // start range here
        if (s2DataItem.valueY > s1DataItem.valueY) {
          if (!negativeRange) {
            negativeRange = dateAxis.createSeriesRange(series);
            negativeRange.date = new Date(startTime);
            negativeRange.contents.fill = series2.fill;
            negativeRange.contents.fillOpacity = 0.8;
          }
        }
        else {
          // if negative range started
          if (negativeRange) {
            negativeRange.endDate = new Date(startTime);
          }
          negativeRange = undefined;
        }
        // end if last
        if (s1DataItem.index == series.dataItems.length - 1) {
          if (negativeRange) {
            negativeRange.endDate = new Date(s1DataItem.dateX.getTime() + dateAxis.baseDuration / 2);
            negativeRange = undefined;
          }
        }
      })
    })
  }
}
