export class LoadCicdProjects {
    static type = 'LoadCicdProjects'
    constructor() { }
}

export class LoadDataTableCicdProjects {
    static type = 'LoadDataTableCicdProjects'
    constructor() { }
}

export class UpdateCountersCicdProjects {
    static type = 'UpdateCountersCicdProjects'
    constructor() { }
}

export class ClearCicdProject {
    static type = 'ClearCicdProject'
    constructor() { }
}

export class SetCicdProject {
    static type = 'SetCicdProject'
    constructor(public payload: any) { }
}

export class LoadCicdProjectsCharts {
    static type = 'LoadCicdProjectsCharts'
    constructor() { }
}

export class LoadCicdProjectsChartsFilteredData {
    static type = 'LoadCicdProjectsChartsFilteredData'
    constructor() { }
}
