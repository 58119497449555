import { Pipe, PipeTransform } from '@angular/core';
import { EmergencyEnum } from '../enum/emergency.enum';
import { ColorEnum } from '../enum/color.enum';

@Pipe({
  name: 'emergencyColor'
})
export class EmergencyColorPipe implements PipeTransform {

  transform(emergency: number): string {
    let result = undefined
    switch (emergency) {
      case EmergencyEnum.MODERATE:
        result = ColorEnum.emergency_moderade
        break;

      case EmergencyEnum.HIGH:
        result = ColorEnum.emergency_high
        break;

      case EmergencyEnum.CRITICAL:
        result = ColorEnum.emergency_critical
        break;
    
      case EmergencyEnum.NONE:
      default:
        result = ColorEnum.emergency_none
        break;
    }
    return result
  }
}
