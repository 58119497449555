import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/core/config.service";
import { FinOpsAwsAccountsDayMeasures } from "./finops-aws-accounts.model";
import { FinOpsContextConfig } from "../finops-context/finops-context.model";
import { DateService, HistoricalDate } from "src/app/shared/service/date.service";
import { DateGranularityEnum } from "src/app/shared/enum/date-granularity.enum";
import { forkJoin } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FinOpsAwsAccountsService {

    private cost_target = "costs-services"

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigService,
        private dateService: DateService
    ) {
    }

    loadFinOpsAwsAccountsHistoricalData(config: FinOpsContextConfig) {
        let httpCalls = []
        let historicalDates: HistoricalDate[] = this.dateService.getHistoricalDates(config, 3)
        for (let historicalDate of historicalDates) {
            httpCalls.push(this.httpClient.get<FinOpsAwsAccountsDayMeasures>(`${this.configService.config.apiUrl}/list-finops-aws-accounts-data?previous_date=${historicalDate.previousDate}&date=${historicalDate.date}&granularity=${DateGranularityEnum[config.granularity]}&cost_target=${this.cost_target}`))
        }
        return forkJoin(httpCalls)
    }

    loadFinOpsAwsAccountsChartsData(config: FinOpsContextConfig) {
        let httpCalls = []
        let historicalDates: HistoricalDate[] = this.dateService.getHistoricalDates(config, config.depth)
        for (let historicalDate of historicalDates) {
            httpCalls.push(this.httpClient.get<FinOpsAwsAccountsDayMeasures>(`${this.configService.config.apiUrl}/list-finops-aws-accounts-charts?date=${historicalDate.date}&cost_target=${this.cost_target}`))
        }
        return forkJoin(httpCalls)
    }
}
