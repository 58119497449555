import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Config } from './config';
import {tap} from "rxjs/operators";
import { Amplify } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _config: Config;

  constructor(private http: HttpClient) {
    this._config = {
      apiUrl: "",
      amplify: {
        Auth: {
          region: "",
          userPoolId: "",
          userPoolWebClientId: "",
          oauth: {
            domain: "",
            scope: [""],
            redirectSignIn: "",
            redirectSignOut: "",
            responseType: ""
          }
        }
      },
      lang: {
        default: "",
        use:""
      }
    }
  }

  loadConfig() {
    return this.http
      .get<Config>('./assets/config.json')
      .toPromise()
      .then(config => {
        if(config!=undefined){
          this._config = config;
          Amplify.configure(config.amplify);
        }
      });
  }

  get config(): Config {
    return this._config;
  }
}
