import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeComponent {

  @Input()
  title:string = ''

  @Input()
  badgeContentText: any
  @Input()
  badgeContentColor?: any

  @Input()
  badgeLabelText?: any
  @Input()
  badgeLabelColor?: any

  private _BASE_URL: string = 'https://img.shields.io/badge/'

  public constructBadgeURL(badgeContentText: string, badgeContentColor?: string, badgeLabelText?:string, badgeLabelColor?: string) {

    let rightBadgePart=this.constructBadgeContent(badgeContentText, badgeContentColor);
    let finalURL = this._BASE_URL;

    if(badgeLabelText) {
      let leftBadgePart = this.constructLabelContent(badgeLabelText, badgeLabelColor);
      return finalURL.concat(rightBadgePart,'?',leftBadgePart)
    }
    return finalURL.concat(rightBadgePart);

  }

  private constructBadgeContent(text: string, color?: string) {
    return color ?
        this.adaptValue(text).concat('-', color)
      : this.adaptValue(text).concat('-', 'gray') ;
  }

  private constructLabelContent(text?: string, color?: string) {

    const LABEL = 'label='
    const LABEL_COLOR = '&labelColor='
    return text ?
      (color ?
        LABEL.concat((text), LABEL_COLOR, color) : LABEL.concat((text)))
      : '' ;
  }

  private adaptValue(text: string): string {
    return text.replaceAll('-','--').replaceAll('_','__')
  }
}
