import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/core/config.service";
import { CicdFactorisationsData} from "./cicd-factorisations.model";
import { map } from "rxjs";
import { CicdProjectTypeEnum } from "src/app/shared/enum/cicd-project-type.enum";
import { CicdProjects } from "../cicd-projects/cicd-projects.model";

@Injectable({
    providedIn: 'root'
})
export class CicdFactorisationsService {

    constructor(private httpClient: HttpClient, private configService: ConfigService){

    }

    loadCicdFactorisationsData(){
        return this.httpClient.get<CicdFactorisationsData>(`${this.configService.config.apiUrl}/list-cicd-factorisations-data`)
        .pipe(
            map((response=>{
                const data = []
                const projectEnumKeys = Object.keys(CicdProjectTypeEnum);
                for(let type in response){
                    const projects = response[type as keyof CicdProjects]
                    if(projectEnumKeys.includes(type)){
                        data.push(...projects.checked.map((value) => {return {...value, type: type}}))
                        data.push(...projects.unchecked.map((value) => {return {...value, type: type}}))
                        data.push(...projects.ignored.map((value) => {return {...value, type: type}}))
                    }
                }
                return data
            }))
        )
    }
}
