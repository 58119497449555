import { Injectable } from '@angular/core';
import { ActionType, Actions, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  subscriptions: Map<string, Set<Subscription>> = new Map<string, Set<Subscription>>();

  constructor(
    private actions: Actions
  ) { }

  private getSubscriptions(className: string) {
    let result = this.subscriptions.get(className)
    if (result === undefined) {
      result = new Set<Subscription>()
      this.subscriptions.set(className, result)
    }
    return result
  }

  public subscribeActionSuccessful(className: string, subscription: ActionType, actionToExecute: ((value: any) => void)) {
    this.getSubscriptions(className).add(
      this.actions.pipe(
        ofActionSuccessful(subscription)
      ).subscribe(actionToExecute)
    )
  }

  public subscribeActionsSuccessful(className: string, subscriptions: ActionType[], actionToExecute: ((value: any) => void)) {
    this.getSubscriptions(className).add(
      this.actions.pipe(
        ofActionSuccessful(...subscriptions)
      ).subscribe(actionToExecute)
    )
  }

  public subscribeObserver(className: string, subscription: Observable<any>, actionToExecute: ((value: any) => void)) {
    this.getSubscriptions(className).add(
      subscription.pipe().subscribe(actionToExecute)
    )
  }

  public unsubscribeAll(className: string) {
    this.getSubscriptions(className).forEach((subscription) => {
      subscription.unsubscribe();
    })
  }
}
