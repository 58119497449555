import { Component } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

  isDisplayed: boolean = true;

  public discard(): void {
    this.isDisplayed = false;
  }
}
