import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input()
  width = 'None'

  @Input()
  height = '3rem'

  @Input()
  backgroundColor = 'black'

  @Input()
  textColor = 'white'

  @Input()
  label = ''

  @Input()
  description = ''

  @Input()
  img = ''

  @Input()
  disabled = false

  @Input()
  callbackFunction!: () => void

  getBackgroundColor() {
    if (this.disabled) {
      return "var(--color_button_background_disabled)"
    }
    return this.backgroundColor
  }

  getTextColor() {
    if (this.disabled) {
      return "var(--color_button_text_disabled)"
    }
    return this.textColor
  }
}
