import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken, Store } from "@ngxs/store";
import { OnOffResourcesService } from "./finops-aws-on-off.service";
import {
    LoadOnOffHistoricalData,
    LoadDataTableOnOffResources,
    LoadOnOffChartsData,
    LoadOnOffChartsFilteredData
} from "./finops-aws-on-off.actions";
import { map, tap } from "rxjs";
import { FinOpsContextState } from "../finops-context/finops-context.state";
import { OnOffResource, OnOffResourcesStateModel, OnOffCounters } from "./finops-aws-on-off.model";
import { FiltersState } from "../filters/filters.state";
import { ItemTypeEnum } from "src/app/shared/enum/item-type.enum";

const FINOPS_TOKEN: StateToken<OnOffResourcesStateModel> = new StateToken('onOffResources');

const OnOffResourcesStateModelDefaults: OnOffResourcesStateModel = {
    historicalData: {
        measures: {}
    },
    datatable: [],
    chartsData: {
        date: "",
        measures: []
    },
    filteredChartsData: [],
    filteredCounters: {}
}

@State<OnOffResourcesStateModel>({
    name: FINOPS_TOKEN,
    defaults: OnOffResourcesStateModelDefaults
})

@Injectable()
export class OnOffResourcesState {

    constructor(
        private onOffResourcesService: OnOffResourcesService,
        private store: Store
    ) {
    }

    @Selector()
    static getHistoricalData(state: OnOffResourcesStateModel) {
        return state.historicalData
    }

    @Selector()
    static getDatatable(state: OnOffResourcesStateModel) {
        return state.datatable
    }

    @Selector()
    static getFilteredCounters(state: OnOffResourcesStateModel) {
        return state.filteredCounters
    }

    @Selector()
    static getChartsData(state: OnOffResourcesStateModel) {
        return state.chartsData
    }

    @Selector()
    static getFilteredChartsData(state: OnOffResourcesStateModel) {
        return state.filteredChartsData
    }

    @Action(LoadOnOffHistoricalData)
    loadOnOffResourcesHistoricalData(ctx: StateContext<OnOffResourcesStateModel>) {
        let config = this.store.selectSnapshot(FinOpsContextState.getConfig)
        let selectedAccount = this.store.selectSnapshot(FinOpsContextState.getSelectedAccount)
        return this.onOffResourcesService.loadOnOffResourcesHistoricalData(
            config,
            selectedAccount
        ).pipe(
            map((result) => {
                let measures: Record<string, OnOffResource[]> = {}
                result.forEach(dayResult => {
                    measures[dayResult.date] = dayResult.measures
                });
                return ctx.patchState({
                    historicalData: {
                        measures: measures
                    }
                })
            })
        )
    }

    @Action(LoadDataTableOnOffResources)
    loadDataTableOnOffResources(ctx: StateContext<OnOffResourcesStateModel>) {
        let historicalData = ctx.getState().historicalData
        let selectedDate = this.store.selectSnapshot(FinOpsContextState.getSelectedDate)
        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.ON_OFF_RESOURCES]]
        let measuresDatatable: { [id: string]: OnOffResource[]; } = {}
        let filteredCounters: { [id: string]: Record<string, OnOffCounters>; } = {}
        Object.keys(historicalData.measures).forEach(measureDate => {
            let datatable: OnOffResource[] = []
            Object.values(historicalData.measures[measureDate]).forEach((resource: any) => {
                datatable.push(resource)
            });
            filters.forEach(filter => {
                if (filter.key == 'project') {
                    datatable = datatable.filter((resource) => {
                        let projectsFound = false
                        resource.projects.forEach(project => {
                            projectsFound = projectsFound || filter.value.includes(project)
                        })
                        return projectsFound
                    })
                } else if (filter.key == 'tag') {
                    datatable = datatable.filter((resource) => {
                        let tagFound = false
                        Object.entries(resource.tags).forEach((tag: any) => {
                            let tagKey = tag[0]
                            let tagValue = tag[1]
                            filter.value.forEach((filterValue: any) => {
                                let filterTagKey = filterValue.substring(0, filterValue.indexOf("="))
                                let filterTagValue = filterValue.substring(filterValue.indexOf("=") + 1)
                                tagFound = tagFound || (tagKey == filterTagKey && tagValue == filterTagValue)
                            });
                        })
                        return tagFound
                    })
                } else if (filter.key == 'age') {
                    let unknownAge = 99999
                    let filterAge: number = unknownAge
                    if (filter.value == 'today') {
                        filterAge = 0
                    } else if (filter.value.startsWith('last_')) {
                        filterAge = filter.value.substring(5, 7)
                    }
                    datatable = datatable.filter((resource) => {
                        let ageInRange = false
                        if (filterAge == unknownAge) {
                            ageInRange = resource.age == filterAge
                        } else {
                            ageInRange = resource.age <= filterAge
                        }
                        return ageInRange
                    })
                } else if (filter.key.startsWith('tag_')) {
                    let tagKey = filter.key.substring(4)
                    if (Array.isArray(filter.value)) {
                        datatable = datatable.filter((resource) => Object.entries(resource['tags']).find(pair => pair[0] === tagKey && filter.value.includes(pair[1])))
                    } else {
                        datatable = datatable.filter((resource) => Object.entries(resource['tags']).find(pair => pair[0] === tagKey && pair[1] === filter.value))
                    }
                } else {
                    if (Array.isArray(filter.value)) {
                        datatable = datatable.filter((resource) => filter.value.includes(resource[filter.key as keyof OnOffResource]))
                    } else {
                        datatable = datatable.filter((resource) => resource[filter.key as keyof OnOffResource] === filter.value)
                    }
                }
            });
            measuresDatatable[measureDate] = datatable

            // Update counters for the measure
            let totalCounters: Record<string, OnOffCounters> = {
                "year": {
                    prediction: false,
                    previous_on_hours: 0,
                    on_hours: 0,
                    off_hours: 0,
                    day_ratio: 0,
                    gap_ratio: 0,
                    gap_value: 0
                },
                "month": {
                    prediction: false,
                    previous_on_hours: 0,
                    on_hours: 0,
                    off_hours: 0,
                    day_ratio: 0,
                    gap_ratio: 0,
                    gap_value: 0
                },
                "day": {
                    prediction: false,
                    previous_on_hours: 0,
                    on_hours: 0,
                    off_hours: 0,
                    day_ratio: 0,
                    gap_ratio: 0,
                    gap_value: 0
                }
            }
            datatable.forEach(row => {
                Object.entries(row.counters).forEach(entries => {
                    let granularity = entries[0]
                    let counters = entries[1]
                    totalCounters[granularity].prediction = counters.prediction
                    totalCounters[granularity].previous_on_hours += Number(counters.previous_on_hours)
                    totalCounters[granularity].on_hours += Number(counters.on_hours)
                    totalCounters[granularity].off_hours += Number(counters.off_hours)
                    totalCounters[granularity].day_ratio = Number(counters.day_ratio)
                });
            });
            Object.keys(totalCounters).forEach(granularity => {
                totalCounters[granularity].previous_on_hours = Math.round(totalCounters[granularity].previous_on_hours * 100) / 100
                totalCounters[granularity].on_hours = Math.round(totalCounters[granularity].on_hours * 100) / 100
                totalCounters[granularity].off_hours = Math.round(totalCounters[granularity].off_hours * 100) / 100
                totalCounters[granularity].day_ratio = Math.round(totalCounters[granularity].day_ratio * 100) / 100
                let gap_value = totalCounters[granularity].on_hours - totalCounters[granularity].previous_on_hours
                totalCounters[granularity].gap_ratio = totalCounters[granularity].previous_on_hours == 0 ? 100 : Math.round((gap_value / totalCounters[granularity].previous_on_hours) * 10000) / 100
                totalCounters[granularity].gap_value = Math.round(gap_value * 100) / 100
            });
            filteredCounters[measureDate] = totalCounters
        });

        ctx.patchState({
            datatable: measuresDatatable[selectedDate],
            filteredCounters: filteredCounters
        })
    }

    @Action(LoadOnOffChartsData)
    loadOnOffChartsData(ctx: StateContext<OnOffResourcesStateModel>, payload: any) {
        let config = this.store.selectSnapshot(FinOpsContextState.getConfig)
        let selectedAccount = this.store.selectSnapshot(FinOpsContextState.getSelectedAccount)
        return this.onOffResourcesService.loadOnOffResourcesChartsData(
            config,
            selectedAccount
        ).pipe(
            tap(value => {
                return ctx.patchState({
                    chartsData: value
                })
            })
        )
    }

    @Action(LoadOnOffChartsFilteredData)
    loadOnOffChartsFilteredData(ctx: StateContext<OnOffResourcesStateModel>) {
        let chartsData = ctx.getState().chartsData
        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.ON_OFF_RESOURCES_CHARTS]]
        let filteredChartsData: OnOffResource[] = []
        chartsData.measures.forEach((measure: any) => {
            filteredChartsData.push(measure)
        });
        filters.forEach(filter => {
            if (filter.key == 'project') {
                filteredChartsData = filteredChartsData.filter((resource) => {
                    let projectsFound = false
                    resource.projects.forEach(project => {
                        projectsFound = projectsFound || filter.value.includes(project)
                    })
                    return projectsFound
                })
            } else if (filter.key == 'tag') {
                filteredChartsData = filteredChartsData.filter((resource) => {
                    let tagFound = false
                    Object.entries(resource.tags).forEach((tag: any) => {
                        let tagKey = tag[0]
                        let tagValue = tag[1]
                        filter.value.forEach((filterValue: any) => {
                            let filterTagKey = filterValue.substring(0, filterValue.indexOf("="))
                            let filterTagValue = filterValue.substring(filterValue.indexOf("=") + 1)
                            tagFound = tagFound || (tagKey == filterTagKey && tagValue == filterTagValue)
                        });
                    })
                    return tagFound
                })
            } else if (filter.key == 'age') {
                let unknownAge = 99999
                let filterAge: number = unknownAge
                if (filter.value == 'today') {
                    filterAge = 0
                } else if (filter.value.startsWith('last_')) {
                    filterAge = filter.value.substring(5, 7)
                }
                filteredChartsData = filteredChartsData.filter((resource) => {
                    let ageInRange = false
                    if (filterAge == unknownAge) {
                        ageInRange = resource.age == filterAge
                    } else {
                        ageInRange = resource.age <= filterAge
                    }
                    return ageInRange
                })
            } else if (filter.key.startsWith('tag_')) {
                let tagKey = filter.key.substring(4)
                if (Array.isArray(filter.value)) {
                    filteredChartsData = filteredChartsData.filter((resource) => Object.entries(resource['tags']).find(pair => pair[0] === tagKey && filter.value.includes(pair[1])))
                } else {
                    filteredChartsData = filteredChartsData.filter((resource) => Object.entries(resource['tags']).find(pair => pair[0] === tagKey && pair[1] === filter.value))
                }
            } else {
                if (Array.isArray(filter.value)) {
                    filteredChartsData = filteredChartsData.filter((resource) => filter.value.includes(resource[filter.key as keyof OnOffResource]))
                } else {
                    filteredChartsData = filteredChartsData.filter((resource) => resource[filter.key as keyof OnOffResource] === filter.value)
                }
            }
        });

        ctx.patchState({
            filteredChartsData: filteredChartsData
        })
    }
}
