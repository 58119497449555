import { Injectable } from "@angular/core";
import { Challenge, CicdMeasure, IacMeasure, SummaryStateModel } from "./summary.model";
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { LoadData } from "./summary.actions";
import { tap, map } from "rxjs";
import { SummaryService } from "./summary.service";

const SUMMARY_TOKEN: StateToken<SummaryStateModel> = new StateToken('summary')

const SummaryStateModelDefault: SummaryStateModel = {
    cicd_last_measure: {
        measure: {}
    },
    cicd_challenges: [],
    iac_challenges: [],
    finops_aws_challenges: [],
    iac_last_measure: {
        measure: {}
    },
    new_objective: ""
}

@State<SummaryStateModel>({
    name: SUMMARY_TOKEN,
    defaults: SummaryStateModelDefault
})
@Injectable()
export class SummaryState {

    constructor(private summaryService: SummaryService) { }

    @Selector()
    static getCicdLastMeasure(state: SummaryStateModel): CicdMeasure {
        return state.cicd_last_measure
    }

    @Selector()
    static getIacLastMeasure(state: SummaryStateModel): IacMeasure {
        return state.iac_last_measure
    }

    @Selector()
    static getAllChallenges(state: SummaryStateModel): Challenge[] {
        return [...state.iac_challenges, ...state.cicd_challenges, ...state.finops_aws_challenges]
    }

    @Selector()
    static getNewObjective(state: SummaryStateModel): String {
        return state.new_objective
    }

    @Action(LoadData)
    loadData(ctx: StateContext<SummaryStateModel>) {
        return this.summaryService.getSummary()
            .pipe(
                map(value => {
                    return new SummaryStateModel(value.cicd_last_measure, value.iac_last_measure, value["cicd-challenges"], value['iac-challenges'], value['finops-aws-challenges'], value.new_objective)
                }),
                tap(value => {
                    ctx.patchState(value)
                })
            )
    }
}
