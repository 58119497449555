import { FinOpsContextConfig } from "./finops-context.model"

export class SetFinOpsContextConfig {
    static type = 'SetFinOpsContextConfig'
    constructor(public payload: FinOpsContextConfig) { }
}

export class SetFinOpsContextSelectedDate {
    static type = 'SetFinOpsContextSelectedDate'
    constructor(public payload: string) { }
}

export class SetFinOpsContextSelectedOrganization {
    static type = 'SetFinOpsContextSelectedOrganization'
    constructor(public payload: string) { }
}

export class SetFinOpsContextSelectedAccount {
    static type = 'SetFinOpsContextSelectedAccount'
    constructor(public payload: string) { }
}