import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/core/config.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor( private configService: ConfigService, private httpClient: HttpClient) {
  }

  getProfile(){
    return this.httpClient.get<any>(`${this.configService.config.apiUrl}/update-connection`)
  }

  getProfileImage(path:string){
    const uri = encodeURIComponent(`images/users/${path}.txt`)
    return this.httpClient.get(`${this.configService.config.apiUrl}/get-image/${uri}`, {
      responseType:'text'
    })
  }
}
