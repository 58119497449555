import { ItemTypeEnum } from "src/app/shared/enum/item-type.enum";
import { Filter, FiltersInput } from "./filters.model";

export class InitFilters {
  static type = 'InitFilters'
  constructor() { }
}

export class AddFilter {
  static type = 'AddFilter'
  constructor(public payload: ItemTypeEnum, public filter: Filter) { }
}

export class RemoveFilter {
  static type = 'RemoveFilter'
  constructor(public payload: ItemTypeEnum, public filterKey: string) { }
}

export class SetFilters {
  static type = 'SetFilters'
  constructor(public payload: ItemTypeEnum, public filters: Filter[]) { }
}

export class ResetFilters {
  static type = 'ResetFilters'
  constructor(public payload: ItemTypeEnum) { }
}

export class ListFiltersInputs {
  static type = 'ListFiltersInputs'
  constructor() { }
}

export class SaveFiltersInput {
  static type = 'SaveFiltersInput'
  constructor(public payload: FiltersInput) { }
}

export class DeleteFiltersInput {
  static type = 'DeleteFiltersInput'
  constructor(public payload: FiltersInput) { }
}
