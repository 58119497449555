export class LoadCicdFactorisationsData {
    static type = 'LoadCicdFactorisationsData'
    constructor(){}
}

export class SetCicdFactorisationMode {
    static type = 'SetCicdFactorisationMode'
    constructor(public payload: any){}
}

export class ClearCicdFactorisationsProject {
    static type = 'ClearCicdFactorisationsProject'
    constructor(){}
}

export class SetCicdFactorisationsProject {
    static type = 'SetCicdFactorisationsProject'
    constructor(public payload: any){}
}