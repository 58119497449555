export class LoadChallenges {
    static type = 'LoadChallenges'
}

export class SetChallenge {
    static type = 'SetChallenge'
    constructor(public payload: any){}
}

export class SetChallengeProject {
    static type = 'SetChallengeProject'
    constructor(public payload: any){}
}