import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken, Store } from "@ngxs/store";
import { FinOpsAwsAccountsService } from "./finops-aws-accounts.service";
import {
    LoadFinOpsAwsAccountsHistoricalData,
    LoadDataTableFinOpsAwsAccounts,
    LoadFinOpsAwsAccountsChartsData,
    LoadFinOpsAwsAccountsChartsFilteredData
} from "./finops-aws-accounts.actions";
import { map } from "rxjs";
import { FinOpsAwsAccount, FinOpsAwsAccountsStateModel, FinOpsCounters } from "./finops-aws-accounts.model";
import { FinOpsContextState } from "../finops-context/finops-context.state";
import { FiltersState } from "../filters/filters.state";
import { FinOpsChartHistoricalItem } from "src/app/shared/component/chart-finops-historical/chart-finops-historical.component";
import { FinOpsChartCompareItem } from "src/app/shared/component/chart-finops-compare/chart-finops-compare.component";
import { DateGranularityEnum } from "src/app/shared/enum/date-granularity.enum";
import { ColorEnum } from "src/app/shared/enum/color.enum";
import { AwsAccountLabelPipe } from "src/app/shared/pipe/aws-account-label.pipe";
import { DecimalPipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { DateService } from "src/app/shared/service/date.service";
import { ItemTypeEnum } from "src/app/shared/enum/item-type.enum";

const FINOPS_TOKEN: StateToken<FinOpsAwsAccountsStateModel> = new StateToken('finOpsAwsAccounts');

const FinOpsAccountsStateModelDefaults: FinOpsAwsAccountsStateModel = {
    historicalData: {
        measures: {}
    },
    datatable: [],
    chartsData: [],
    filteredHistoricalChartData: {
        minCostRatioString: "",
        minCostValueString: "",
        prediction: false,
        costs: []
    },
    filteredCompareChartData: {
        minCostRatioString: "",
        minCostValueString: "",
        dateString: "",
        prediction: false,
        costs: []
    },
    filteredCounters: {}
}

@State<FinOpsAwsAccountsStateModel>({
    name: FINOPS_TOKEN,
    defaults: FinOpsAccountsStateModelDefaults
})

@Injectable()
export class FinOpsAwsAccountsState {

    constructor(
        private finOpsAwsAccountsService: FinOpsAwsAccountsService,
        private store: Store,
        private awsAccountLabelPipe: AwsAccountLabelPipe,
        private decimalPipe: DecimalPipe,
        private dateService: DateService,
        private translate: TranslateService
    ) {
    }

    @Selector()
    static getHistoricalData(state: FinOpsAwsAccountsStateModel) {
        return state.historicalData
    }

    @Selector()
    static getDatatable(state: FinOpsAwsAccountsStateModel) {
        return state.datatable
    }

    @Selector()
    static getFilteredCounters(state: FinOpsAwsAccountsStateModel) {
        return state.filteredCounters
    }

    @Selector()
    static getChartsData(state: FinOpsAwsAccountsStateModel) {
        return state.chartsData
    }

    @Selector()
    static getFilteredHistoricalChartData(state: FinOpsAwsAccountsStateModel) {
        return state.filteredHistoricalChartData
    }

    @Selector()
    static getFilteredCompareChartData(state: FinOpsAwsAccountsStateModel) {
        return state.filteredCompareChartData
    }

    @Action(LoadFinOpsAwsAccountsHistoricalData)
    loadFinOpsAwsAccountsHistoricalData(ctx: StateContext<FinOpsAwsAccountsStateModel>) {
        let config = this.store.selectSnapshot(FinOpsContextState.getConfig)
        return this.finOpsAwsAccountsService.loadFinOpsAwsAccountsHistoricalData(config).pipe(
            map((result) => {
                let measures: Record<string, FinOpsAwsAccount[]> = {}
                result.forEach(dayResult => {
                    measures[dayResult.date] = dayResult.measures
                });
                return ctx.patchState({
                    historicalData: {
                        measures: measures
                    }
                })
            })
        )
    }

    @Action(LoadDataTableFinOpsAwsAccounts)
    loadDataTableFinOpsAwsAccounts(ctx: StateContext<FinOpsAwsAccountsStateModel>) {
        let historicalData = ctx.getState().historicalData
        let selectedDate = this.store.selectSnapshot(FinOpsContextState.getSelectedDate)
        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.FINOPS_AWS_ACCOUNTS]]
        let measuresDatatable: { [id: string]: FinOpsAwsAccount[]; } = {}
        let filteredCounters: { [id: string]: Record<string, FinOpsCounters>; } = {}
        Object.keys(historicalData.measures).forEach(measureDate => {
            let datatable: FinOpsAwsAccount[] = []
            Object.values(historicalData.measures[measureDate]).forEach(account => {
                datatable.push(account)
            });
            filters.forEach(filter => {
                if (Array.isArray(filter.value)) {
                    datatable = datatable.filter((account) => filter.value.includes(account[filter.key as keyof FinOpsAwsAccount]))
                } else {
                    datatable = datatable.filter((account) => account[filter.key as keyof FinOpsAwsAccount] === filter.value)
                }
            });
            measuresDatatable[measureDate] = datatable

            // Update counters for the measure
            let totalCounters: Record<string, FinOpsCounters> = {
                "year": {
                    prediction: false,
                    previous_cost: 0,
                    cost: 0,
                    day_ratio: 0,
                    gap_ratio: 0,
                    gap_value: 0
                },
                "month": {
                    prediction: false,
                    previous_cost: 0,
                    cost: 0,
                    day_ratio: 0,
                    gap_ratio: 0,
                    gap_value: 0
                },
                "day": {
                    prediction: false,
                    previous_cost: 0,
                    cost: 0,
                    day_ratio: 0,
                    gap_ratio: 0,
                    gap_value: 0
                }
            }
            datatable.forEach(row => {
                Object.entries(row.counters).forEach(entries => {
                    let granularity = entries[0]
                    let counters = entries[1]
                    totalCounters[granularity].prediction = counters.prediction
                    totalCounters[granularity].previous_cost += Number(counters.previous_cost)
                    totalCounters[granularity].cost += Number(counters.cost)
                    totalCounters[granularity].day_ratio = Number(counters.day_ratio)
                });
            });
            Object.keys(totalCounters).forEach(granularity => {
                totalCounters[granularity].previous_cost = Math.round(totalCounters[granularity].previous_cost * 100) / 100
                totalCounters[granularity].cost = Math.round(totalCounters[granularity].cost * 100) / 100
                totalCounters[granularity].day_ratio = Math.round(totalCounters[granularity].day_ratio * 100) / 100
                let gap_value = totalCounters[granularity].cost - totalCounters[granularity].previous_cost
                totalCounters[granularity].gap_ratio = totalCounters[granularity].previous_cost == 0 ? 100 : Math.round((gap_value / totalCounters[granularity].previous_cost) * 10000) / 100
                totalCounters[granularity].gap_value = Math.round(gap_value * 100) / 100
            });
            filteredCounters[measureDate] = totalCounters
        });

        ctx.patchState({
            datatable: measuresDatatable[selectedDate],
            filteredCounters: filteredCounters
        })
    }

    @Action(LoadFinOpsAwsAccountsChartsData)
    loadFinOpsAwsAccountsChartsData(ctx: StateContext<FinOpsAwsAccountsStateModel>, payload: any) {
        let config = this.store.selectSnapshot(FinOpsContextState.getConfig)
        return this.finOpsAwsAccountsService.loadFinOpsAwsAccountsChartsData(
            config
        ).pipe(
            map((measures) => {
                return ctx.patchState({
                    chartsData: measures
                })
            })
        )
    }

    @Action(LoadFinOpsAwsAccountsChartsFilteredData)
    loadFinOpsAwsAccountsChartsFilteredData(ctx: StateContext<FinOpsAwsAccountsStateModel>) {
        let chartsData = ctx.getState().chartsData
        let config = this.store.selectSnapshot(FinOpsContextState.getConfig)
        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.FINOPS_AWS_ACCOUNTS_CHARTS]]
        let historicalChartMinCostRatio: number = 0.0
        let historicalChartMinCostValue: number = 0.0
        let historicalChartCosts: FinOpsChartHistoricalItem[] = []
        let compareMinCostRatio: number = 0.01
        let compareMinCostValue: number = 0.0
        let compareChartCosts: FinOpsChartCompareItem[] = []
        let prediction: boolean = false

        chartsData.forEach(measureDate => {
            // Filter accounts
            let filteredAccounts: FinOpsAwsAccount[] = []
            measureDate.measures.forEach(measure => {
                filteredAccounts.push(measure)
            });
            filters.forEach(filter => {
                if (Array.isArray(filter.value)) {
                    filteredAccounts = filteredAccounts.filter((account) => filter.value.includes(account[filter.key as keyof FinOpsAwsAccount]))
                } else {
                    filteredAccounts = filteredAccounts.filter((account) => account[filter.key as keyof FinOpsAwsAccount] === filter.value)
                }
            });

            // Compute historical chart data for the current date
            let totalCosts: number = 0
            prediction = false
            filteredAccounts.forEach(account => {
                totalCosts += Number(account.counters[DateGranularityEnum[config.granularity]].cost)
                prediction = account.counters[DateGranularityEnum[config.granularity]].prediction
            });
            historicalChartCosts.push(
                {
                    date: new Date(measureDate.date),
                    cost: Math.round(totalCosts * 100) / 100,
                    dateString: this.dateService.formatLongDate(measureDate.date),
                    predictionString: prediction ? this.translate.instant("FINOPS.PREDICTION") + "\n\n" : ""
                }
            )

            // Compute compare charts data only for the last date
            if (measureDate == chartsData[chartsData.length - 1]) {
                compareMinCostValue = totalCosts * compareMinCostRatio
                filteredAccounts.forEach(account => {
                    let accountCost = Number(account.counters[DateGranularityEnum[config.granularity]].cost)
                    if (accountCost > compareMinCostValue) {
                        let colorKey = 'charts_color_' + compareChartCosts.length
                        compareChartCosts.push(
                            {
                                category: this.awsAccountLabelPipe.transform(account.id),
                                cost: Math.round(accountCost * 100) / 100,
                                color: ColorEnum[colorKey as keyof typeof ColorEnum]
                            }
                        )
                    }
                });
            }
        });

        ctx.patchState({
            filteredHistoricalChartData: {
                minCostRatioString: historicalChartMinCostRatio == 0 ? "" : this.decimalPipe.transform(historicalChartMinCostRatio * 100)!,
                minCostValueString: historicalChartMinCostValue == 0 ? "" : this.decimalPipe.transform(historicalChartMinCostValue, "1.2-2")!,
                prediction: prediction,
                costs: historicalChartCosts
            },
            filteredCompareChartData: {
                minCostRatioString: compareMinCostRatio == 0 ? "" : this.decimalPipe.transform(compareMinCostRatio * 100)!,
                minCostValueString: compareMinCostValue == 0 ? "" : this.decimalPipe.transform(compareMinCostValue, "1.2-2")!,
                dateString: this.dateService.formatLongDate(chartsData[chartsData.length - 1].date),
                prediction: prediction,
                costs: compareChartCosts
            }
        })
    }
}
