import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-finops-title',
  templateUrl: './finops-title.component.html',
  styleUrls: ['./finops-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinOpsTitleComponent {

  @Input()
  label = ''

  @Input()
  img = ''
}
