import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntraIdResourceTypeEnum } from '../enum/entra-id-resource-type.enum';

@Pipe({
  name: 'entraIdResourceTypeLabel'
})
export class EntraIdResourceTypeLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService) {

  }

  transform(type: string): string {
    const entraIdEnumKeys = Object.keys(EntraIdResourceTypeEnum);
    if (entraIdEnumKeys.includes(type)) {
      return this.translate.instant("ENTRA_ID_RESOURCES.TYPE." + type.toUpperCase().replaceAll("-", "_"))
    }
    return type
  }
}
