import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { ConfigService } from './core/config.service';
import { StoreModule } from './store/store.module';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './shared/interceptor/http-request-interceptor.interceptor';

export const configFactory = (configService: ConfigService) => {
  return () =>  configService.loadConfig();
}
@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        BrowserModule,
        HammerModule,
        CoreModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        StoreModule,
        BreadcrumbModule
    ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor, 
      multi: true 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
