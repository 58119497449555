import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';

export type FinOpsChartCompareItem = {
  category: string,
  cost: number,
  color: string
}

export type FinOpsChartCompareData = {
  minCostRatioString: string,
  minCostValueString: string,
  dateString: string,
  prediction: boolean,
  costs: FinOpsChartCompareItem[]
}

@Component({
  selector: 'app-chart-finops-compare',
  templateUrl: './chart-finops-compare.component.html',
  styleUrls: ['./chart-finops-compare.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartFinOpsCompareComponent implements AfterViewInit {

  @Input() public title: string = "";

  @Input() public chartWidth: string = "100%";

  @Input() public chartHeight: string = "500px";

  @Input() public legendPosition: string = "right";

  @Input() public legendSize: string = "200";

  private chart: any;

  public chartDivId = uuidv4();

  public chartTitle = "";

  public chartSubTitle = "";

  /**
   * Constructor
   */
  constructor(
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  /**
   * Clean up charts
   */
  public cleanUp() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  /**
   * Refresh the data
   */
  public refresh(data: FinOpsChartCompareData) {
    this.chart.data = data.costs;
    this.chartTitle = this.title;
    if (data.minCostRatioString != "") {
      this.chartTitle += " > $" + data.minCostValueString + " (" + data.minCostRatioString + "%)"
    }
    this.chartSubTitle = data.dateString
    if (data.prediction) {
      this.chartSubTitle += " "
      this.chartSubTitle += this.translate.instant("FINOPS.PREDICTION")
    }
    this.changeDetectorRef.detectChanges();
  }

  getChartStyle() {
    return "width: " + this.chartWidth + " ; height: " + this.chartHeight
  }

  ngAfterViewInit(): void {
    // Chart code goes in here
    am4core.useTheme(am4themes_animated);

    // Create chart for Versus
    this.chart = am4core.create(this.chartDivId, am4charts.PieChart);

    // Add and configure Series
    let pieSeries = this.chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "cost";
    pieSeries.dataFields.category = "category";
    pieSeries.innerRadius = am4core.percent(50);
    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;
    pieSeries.slices.template.tooltipText = "{category}: ${cost} ({value.percent.formatNumber('#.00')}%)"
    pieSeries.slices.template.propertyFields.fill = "color";

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    // Add legend
    if (this.legendSize != "0" && this.legendPosition != "") {
      this.chart.legend = new am4charts.Legend();
      this.chart.legend.position = this.legendPosition;
      this.chart.legend.valueLabels.template.text = "{value.percent.formatNumber('#.00')}%"
      if (this.legendPosition == "top" || this.legendPosition == "bottom") {
        this.chart.legend.height = this.legendSize;
      } else {
        this.chart.legend.width = this.legendSize;
      }
    }

    this.chart.hiddenState.properties.radius = am4core.percent(0);
  }
}
