import { StateToken, State, Action, StateContext, Selector} from "@ngxs/store";
import { ProfileStoreModel, User } from "./profile.model";
import { Injectable } from "@angular/core";
import { LoadImage, LoadProfile } from "./profile.actions";
import { ProfileService } from "./profile.service";
import { mergeMap, of, tap } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";

const PROFILE_TOKEN: StateToken<ProfileStoreModel> = new StateToken('profile');
const ProfileStoreModelDefault: ProfileStoreModel = {
  user: {
    companyFunction:'',
    firstName: '',
    lastName: '',
    imageAvailable: false,
    mail: '',
    challenger: false,
    image: ''
  }
}


@State<ProfileStoreModel>({
    name: PROFILE_TOKEN,
    defaults: ProfileStoreModelDefault
})
@Injectable()
export class ProfileState {

    constructor(private profileService: ProfileService, private sanitizer: DomSanitizer){

    }

    @Selector()
    static getUser(state: ProfileStoreModel): User {
        return state.user
    }

    @Action(LoadProfile)
    loadProfile(ctx: StateContext<ProfileStoreModel>){
        return this.profileService.getProfile()
        .pipe(
            tap(value=>{
                ctx.patchState(value)
            }),
            mergeMap(()=> ctx.dispatch(LoadImage))
        )
    }

    @Action(LoadImage)
    loadImage(ctx: StateContext<ProfileStoreModel>){
        const user = ctx.getState().user
        if(user.imageAvailable){
            return this.profileService.getProfileImage(user.mail)
            .pipe(
                tap(value=> {
                    let pUser = ctx.getState().user
                    const image = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${value}`)
                    const user: User = {
                        companyFunction: pUser.companyFunction,
                        firstName: pUser.firstName,
                        lastName: pUser.lastName,
                        mail: pUser.lastName,
                        challenger: pUser.challenger,
                        image: image,
                        imageAvailable: pUser.imageAvailable
                    }
                    ctx.patchState({user})
                }))
        } else {
            return of('')
            .pipe(
                tap(value => {
                    let pUser = ctx.getState().user
                    const user: User = {
                        companyFunction: pUser.companyFunction,
                        firstName: pUser.firstName,
                        lastName: pUser.lastName,
                        mail: pUser.lastName,
                        challenger: pUser.challenger,
                        image: null,
                        imageAvailable: pUser.imageAvailable
                    }
                    ctx.patchState({user})
                })
            )
        }
    }
}
