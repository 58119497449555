import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FinOpsCounters } from 'src/app/store/finops-aws-services/finops-aws-services.model';
import { SetFinOpsContextSelectedDate } from 'src/app/store/finops-context/finops-context.actions';
import { FinOpsContextState } from 'src/app/store/finops-context/finops-context.state';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DateGranularityEnum } from '../../enum/date-granularity.enum';
import { DateService } from '../../service/date.service';

@Component({
  selector: 'app-finops-date',
  templateUrl: './finops-date.component.html',
  styleUrls: ['./finops-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinOpsDateComponent {

  @Select(FinOpsContextState.getSelectedDate) selectedDate$!: Observable<string>;

  @Input()
  date = ''

  @Input()
  granularity: string = DateGranularityEnum[DateGranularityEnum.month]

  @Input()
  counters: Record<string, FinOpsCounters> = {}

  constructor(
    private store: Store,
    private translate: TranslateService,
    private decimalPipe: DecimalPipe,
    private dateService: DateService
  ) { }

  getBackground(selected: boolean) {
    if (this.counters[this.granularity].prediction) {
      if (selected) {
        return 'var(--color_button_stripped_background_selected)'
      }
      return 'var(--color_button_stripped_background_unselected)'
    }
    if (selected) {
      return 'var(--color_button_background_selected)'
    }
    return 'var(--color_button_background_unselected)'
  }

  getForeground(selected: boolean) {
    if (selected) {
      return 'var(--color_button_text_selected)'
    }
    return 'var(--color_button_text_unselected)'
  }

  getFormattedDate(date: string) {
    return this.dateService.formatLongDate(date);
  }

  getTitle() {
    let config = this.store.selectSnapshot(FinOpsContextState.getConfig)
    let title = "";
    if (config.billingDay) {
      title = this.translate.instant("FINOPS.BILLING_DAY", {
        value: this.dateService.formatLongDate(this.date)
      })
    } else {
      title = this.translate.instant("FINOPS.DAY", {
        value: this.dateService.formatLongDate(this.date)
      })
    }
    if (this.counters[this.granularity].prediction) {
      title += "\n\n"
      title += this.translate.instant("FINOPS.PREDICTION")
    }
    Object.entries(this.counters).forEach(entries => {
      let granularity = entries[0]
      let counters = entries[1]
      title += "\n\n" + this.translate.instant("FINOPS.COUNTERS", {
        granularity: granularity
      })
      title += "\n" + this.translate.instant("FINOPS.DAY_RATIO", {
        value: this.decimalPipe.transform(counters.day_ratio, "1.2-2")
      })
      title += "\n" + this.translate.instant("FINOPS.PREVIOUS_COST", {
        value: this.decimalPipe.transform(counters.previous_cost, "1.2-2")
      })
      title += "\n" + this.translate.instant("FINOPS.COST", {
        value: this.decimalPipe.transform(counters.cost, "1.2-2")
      })
      title += "\n" + this.translate.instant("FINOPS.GAP", {
        value: this.decimalPipe.transform(counters.gap_value),
        ratio: this.decimalPipe.transform(counters.gap_ratio, "1.2-2")
      })
    });
    return title
  }

  changeSelectedDate() {
    this.store.dispatch([new SetFinOpsContextSelectedDate(this.date)])
  }
}
