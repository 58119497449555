import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { TranslateService } from '@ngx-translate/core';
import { ColorEnum } from '../../enum/color.enum';
import { v4 as uuidv4 } from 'uuid';

export type ChartComplianceRatioItem = {
  date: Date,
  value: string,
  dateString: string
}

export type ChartComplianceRatioData = {
  values: ChartComplianceRatioItem[]
}

@Component({
  selector: 'app-chart-compliance-ratio',
  templateUrl: './chart-compliance-ratio.component.html',
  styleUrls: ['./chart-compliance-ratio.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComplianceRatioComponent implements AfterViewInit {

  @Input() public chartWidth: string = "100%";

  @Input() public chartHeight: string = "300px";

  private chart: any;

  public chartDivId = uuidv4();

  /**
   * Constructor
   */
  constructor(
    private translate: TranslateService
  ) {
  }

  /**
   * Clean up charts
   */
  public cleanUp() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  /**
   * Refresh the data
   */
  public refresh(data: ChartComplianceRatioData) {
    this.chart.data = data.values;
  }

  getChartStyle() {
    return "width: " + this.chartWidth + " ; height: " + this.chartHeight
  }

  ngAfterViewInit(): void {
    // Chart code goes in here
    am4core.useTheme(am4themes_animated);
    let seriesColor = am4core.color(ColorEnum.charts_default);

    this.chart = am4core.create(this.chartDivId, am4charts.XYChart);

    this.chart.paddingRight = 20;

    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.minZoomCount = 5;

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.events.on("ready", function (ev: any) {
      ev.target.min = 0;
      ev.target.max = 100;
    });

    let series = this.chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";
    series.strokeWidth = 2;
    series.tooltipText = this.translate.instant("CHARTS.FIELD_DATE") + " {dateString}\n" + this.translate.instant("CHARTS.FIELD_COMPLIANT_RATIO") + " {valueY}%";
    series.name = "Compliant Ratio";
    series.stroke = seriesColor;
    series.fill = series.stroke;

    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.xAxis = dateAxis;

    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    this.chart.scrollbarX = scrollbarX;
  }
}
