import { ResolveFn } from '@angular/router';
import { SummaryStateModel } from './store/summary/summary.model';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoadData } from './store/summary/summary.actions';
import { take } from 'rxjs';

export const appResolver: ResolveFn<SummaryStateModel> = (route, state, store: Store = inject(Store)) => {
  return store.dispatch(LoadData)
  .pipe(
    take(1)
  )
};
