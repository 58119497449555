export class LoadGcpResources {
    static type = 'LoadGcpResources'
    constructor() { }
}

export class LoadDataTableGcpResources {
    static type = 'LoadDataTableGcpResources'
    constructor() { }
}

export class UpdateCountersGcpResources {
    static type = 'UpdateCountersGcpResources'
    constructor() { }
}

export class ClearGcpResource {
    static type = 'ClearGcpResource'
    constructor() { }
}

export class SetGcpResource {
    static type = 'SetGcpResource'
    constructor(public payload: any) { }
}

export class LoadGcpCharts {
    static type = 'LoadGcpCharts'
    constructor() { }
}

export class LoadGcpChartsFilteredData {
    static type = 'LoadGcpChartsFilteredData'
    constructor() { }
}
