export class LoadVsphereResources {
    static type = 'LoadVsphereResources'
    constructor() { }
}

export class LoadDataTableVsphereResources {
    static type = 'LoadDataTableVsphereResources'
    constructor() { }
}

export class UpdateCountersVsphereResources {
    static type = 'UpdateCountersVsphereResources'
    constructor() { }
}

export class ClearVsphereResource {
    static type = 'ClearVsphereResource'
    constructor() { }
}

export class SetVsphereResource {
    static type = 'SetVsphereResource'
    constructor(public payload: any) { }
}

export class LoadVsphereCharts {
    static type = 'LoadVsphereCharts'
    constructor() { }
}

export class LoadVsphereChartsFilteredData {
    static type = 'LoadVsphereChartsFilteredData'
    constructor() { }
}
