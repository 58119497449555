import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmergencyEnum } from '../enum/emergency.enum';

@Pipe({
  name: 'emergencyLabel'
})
export class EmergencyLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService){

  }

  transform(emergency: number): string {
    let result = undefined
    switch (emergency) {
      case EmergencyEnum.MODERATE:
        result = this.translate.instant("EMERGENCY.MODERATE")
        break;

      case EmergencyEnum.HIGH:
        result = this.translate.instant("EMERGENCY.HIGH")
        break;

      case EmergencyEnum.CRITICAL:
        result = this.translate.instant("EMERGENCY.CRITICAL")
        break;
    
      case EmergencyEnum.NONE:
      default:
        result = this.translate.instant("EMERGENCY.NONE")
        break;
    }
    return result
  }
}
