export class LoadIacProjects {
    static type = 'LoadIacProjects'
    constructor() { }
}

export class LoadDataTableIacProjects {
    static type = 'LoadDataTableIacProjects'
    constructor() { }
}

export class UpdateCountersIacProjects {
    static type = 'UpdateCountersIacProjects'
    constructor() { }
}

export class ClearIacProject {
    static type = 'ClearIacProject'
    constructor() { }
}

export class SetIacProject {
    static type = 'SetIacProject'
    constructor(public payload: any) { }
}

export class LoadIacProjectsCharts {
    static type = 'LoadIacProjectsCharts'
    constructor() { }
}

export class LoadIacProjectsChartsFilteredData {
    static type = 'LoadIacProjectsChartsFilteredData'
    constructor() { }
}