import { Action, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { ExportsStateModel } from "./exports.model";
import { Injectable } from "@angular/core";
import { ListExportsInputs, DeleteExportsInput, SaveExportsInput } from "./exports.actions";
import { ExportService } from "./exports.service";
import { tap } from "rxjs";

const EXPORTS_TOKEN: StateToken<ExportsStateModel> = new StateToken<ExportsStateModel>('exports')

const ExportsStateModelDefault: ExportsStateModel = {
  exportsInputs: []
}

@State<ExportsStateModel>({
  name: EXPORTS_TOKEN,
  defaults: ExportsStateModelDefault
})

@Injectable()
export class ExportsState {
  constructor(
    private exportService: ExportService
  ) { }

  @Selector()
  static getExportsInputs(state: ExportsStateModel) {
    return state.exportsInputs;
  }

  @Action(ListExportsInputs)
  listExportsInputs(ctx: StateContext<ExportsStateModel>) {
    return this.exportService.listExportsInputs().pipe(
      tap(value => {
        return ctx.patchState({
          exportsInputs: value
        })
      })
    )
  }

  @Action(SaveExportsInput)
  saveExportsInput(ctx: StateContext<ExportsStateModel>, payload: any) {
    return this.exportService.putExportsInput(payload.payload).pipe(
      tap(value => {
        return ctx.patchState({
          exportsInputs: value
        })
      })
    )
  }

  @Action(DeleteExportsInput)
  deleteExportsInput(ctx: StateContext<ExportsStateModel>, payload: any) {
    return this.exportService.deleteExportsInput(payload.payload).pipe(
      tap(value => {
        return ctx.patchState({
          exportsInputs: value
        })
      })
    )
  }
}
