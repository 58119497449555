<mat-card class="card-bonduelle" [ngStyle]="{background: background}">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title class="card-title">{{title}}</mat-card-title>
        <div class="icons-block">
            <img mat-card-sm-image [src]="img" >
        </div>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
     <div class="card-bonduelle-content">
        <div class="render">
            <span class="label">{{"KPI.IAC" | translate}}</span>
            <span class="value">{{value.iac}}</span>
        </div>
        <div class="render">
            <span class="label">{{"KPI.CICD" | translate}}</span>
            <span class="value">{{value.cicd}}</span>
        </div>
     </div>
    </mat-card-content>
</mat-card>