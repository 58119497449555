export class LoadTfModulesData {
    static type = 'LoadTfModulesData'
    constructor(){}
}

export class SetTfModulesFactorisationMode {
    static type = 'SetTfModulesFactorisationMode'
    constructor(public payload: any){}
}

export class ClearTfModuleProject {
    static type = 'ClearTfModuleProject'
    constructor(){}
}

export class SetTfModuleProjectByPath {
    static type = 'SetTfModuleProjectByPath'
    constructor(public payload: any){}
}

export class SetTfModuleProjectByName {
    static type = 'SetTfModuleProjectByName'
    constructor(public name: any) {}
}