import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IacProjectTypeEnum } from '../enum/iac-project-type.enum';

@Pipe({
  name: 'iacProjectTypeLabel'
})
export class IacProjectTypeLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService){

  }

  transform(type: string): string {
    const projectEnumKeys = Object.keys(IacProjectTypeEnum);
    if(projectEnumKeys.includes(type)){
      return this.translate.instant("IAC_PROJECTS.TYPE." + type.toUpperCase().replaceAll("-", "_"))
    }
    return type
  }
}
