import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ProfileState } from './store/profile/profile.state';
import { User } from './store/profile/profile.model';
import { ColorEnum } from './shared/enum/color.enum';
import { InitFilters } from './store/filters/filters.actions';
import { LoadingService } from './shared/service/loading.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  title = 'angular-compliance';
  profile$: Observable<User> = this.store.select(ProfileState.getUser);
  loadingRouterReq: boolean = false;
  loadingHttpReq: boolean = false;

  constructor(private store: Store,
              private loadingService: LoadingService) {
  }

  ngOnInit() {
    this.loadingService.routingLoader();

    this.loadingService.loadingRouterBehaviorSubject.subscribe((isLoadingRoute) => {
      this.loadingRouterReq = isLoadingRoute;
    })
    this.loadingService.loadingHttpRequestBehaviorSubject
      .subscribe((isLoadingHttpReq) => {
        this.loadingHttpReq = isLoadingHttpReq;
    })

    Object.keys(ColorEnum).forEach((key, index) => {
      let value = Object.values(ColorEnum)[index]
      document.body.style.setProperty(`--color_${key}`, value);
    })
    this.store.dispatch(new InitFilters())
  }
}
