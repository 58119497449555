export class LoadFinOpsAwsAccountsHistoricalData {
    static type = 'LoadFinOpsAwsAccountsHistoricalData'
    constructor() { }
}

export class LoadDataTableFinOpsAwsAccounts {
    static type = 'LoadDataTableFinOpsAwsAccounts'
    constructor() { }
}

export class LoadFinOpsAwsAccountsChartsData {
    static type = 'LoadFinOpsAwsAccountsChartsData'
    constructor() { }
}

export class LoadFinOpsAwsAccountsChartsFilteredData {
    static type = 'LoadFinOpsAwsAccountsChartsFilteredData'
    constructor() { }
}
