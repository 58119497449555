import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AwsResourceTypeEnum } from '../enum/aws-resource-type.enum';

@Pipe({
  name: 'awsResourceTypeLabel'
})
export class AwsResourceTypeLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService){

  }

  transform(type: string): string {
    const awsEnumKeys = Object.keys(AwsResourceTypeEnum);
    if(awsEnumKeys.includes(type)){
      return this.translate.instant("AWS_RESOURCES.TYPE." + type.toUpperCase().replaceAll("-", "_"))
    }
    return type
  }
}
