<div class="config">
  <div>
    <mat-form-field>
      <input matInput [max]="maxDate" [matDatepicker]="picker" [value]="this.selectedDate" (dateChange)="selectDate($event)" readonly>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker disabled="false"></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="optional" *ngIf="isGranularityRouting()">
    <mat-checkbox (change)="selectBillingDay($event.checked)" [disabled]="selectedGranularity == DateGranularityEnum.day" [checked]="this.selectedBillingDay">{{"FINOPS.BILLING_DAY_CHOICE" | translate}}</mat-checkbox>
    <mat-form-field *ngIf="isHistoricalChartsRouting()">
      <mat-label>{{"FINOPS.DEPTH_CHOICE" | translate}}</mat-label>
      <mat-select (selectionChange)="selectDepth($event.value)" [value]="this.selectedDepth">
        <mat-option *ngFor="let depth of authorizedDepths" [value]="depth">{{depth}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="buttons" *ngIf="isGranularityRouting()">
    <app-button
      width="9rem"
      [backgroundColor]='selectedGranularity == DateGranularityEnum.day ? "var(--color_button_background_selected)" : "var(--color_button_background_unselected)"'
      [textColor]='selectedGranularity == DateGranularityEnum.day ? "var(--color_button_text_selected)" : "var(--color_button_text_unselected)"'
      [label]="DateGranularityEnum[DateGranularityEnum.day] | dateGranularityLabel"
      [description]="DateGranularityEnum[DateGranularityEnum.day] | dateGranularityLabel"
      [callbackFunction]="selectGranularityDay"/>
    <app-button
      width="9rem"
      [backgroundColor]='selectedGranularity == DateGranularityEnum.month ? "var(--color_button_background_selected)" : "var(--color_button_background_unselected)"'
      [textColor]='selectedGranularity == DateGranularityEnum.month ? "var(--color_button_text_selected)" : "var(--color_button_text_unselected)"'
      [label]="DateGranularityEnum[DateGranularityEnum.month] | dateGranularityLabel"
      [description]="DateGranularityEnum[DateGranularityEnum.month] | dateGranularityLabel"
      [callbackFunction]="selectGranularityMonth"/>
    <app-button
      width="9rem"
      [backgroundColor]='selectedGranularity == DateGranularityEnum.year ? "var(--color_button_background_selected)" : "var(--color_button_background_unselected)"'
      [textColor]='selectedGranularity == DateGranularityEnum.year ? "var(--color_button_text_selected)" : "var(--color_button_text_unselected)"'
      [label]="DateGranularityEnum[DateGranularityEnum.year] | dateGranularityLabel"
      [description]="DateGranularityEnum[DateGranularityEnum.year] | dateGranularityLabel"
      [callbackFunction]="selectGranularityYear"/>
  </div>
</div>
