import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateGranularityLabel'
})
export class DateGranularityLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService){
  }

  transform(dateGranularity: string): string {
    return this.translate.instant("DATE_GRANULARITY." + dateGranularity.toUpperCase())
  }
}
