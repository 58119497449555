import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ConfigService } from "src/app/core/config.service";
import { AwsChartsData, AwsResourceDetails, AwsResources } from "./aws-resources.model";
import {AwsResourceTypeEnum} from "../../shared/enum/aws-resource-type.enum";

@Injectable({
    providedIn: 'root'
})
export class AwsResourcesService {

    constructor(private httpClient: HttpClient, private configService: ConfigService){

    }

    loadAwsResources(accountId: string){
        return this.httpClient.get<AwsResources>(`${this.configService.config.apiUrl}/list-aws-resources-data?account=${accountId}`)
        .pipe(
            map((response=>{
                const data = []
                const awsEnumKeys = Object.keys(AwsResourceTypeEnum);
                for(let type in response){
                    const resources = response[type as keyof AwsResources]
                    if(awsEnumKeys.includes(type)){
                        data.push(...resources.checked.map((value) => {return {...value, type: type}}))
                        data.push(...resources.unchecked.map((value) => {return {...value, type: type}}))
                        data.push(...resources.ignored.map((value) => {return {...value, type: type}}))
                    }
                }
                return data
            }))
        )
    }

    loadAwsResourceDetails(accountId: string, resourceId: string){
        return this.httpClient.get<AwsResourceDetails>(`${this.configService.config.apiUrl}/get-aws-resource-details?account=${accountId}&resource_id=${resourceId}`)
    }

    loadAwsCharts(accountId: string){
        return this.httpClient.get<AwsChartsData>(`${this.configService.config.apiUrl}/list-aws-charts-data?account=${accountId}`)
    }
}
