import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ConfigService } from "src/app/core/config.service";
import { VsphereChartsData, VsphereResourceDetails, VsphereResources } from "./vsphere-resources.model";
import {VsphereResourceTypeEnum} from "../../shared/enum/vsphere-resource-type.enum";

@Injectable({
    providedIn: 'root'
})
export class VsphereResourcesService {

    serverId = "IVPRD010"

    constructor(private httpClient: HttpClient, private configService: ConfigService){

    }

    loadVsphereResources(){
        return this.httpClient.get<VsphereResources>(`${this.configService.config.apiUrl}/list-vsphere-resources-data?server=${this.serverId}`)
        .pipe(
            map((response=>{
                const data = []
                const vsphereEnumKeys = Object.keys(VsphereResourceTypeEnum);
                for(let type in response){
                    const resources = response[type as keyof VsphereResources]
                    if(vsphereEnumKeys.includes(type)){
                        data.push(...resources.checked.map((value) => {return {...value, type: type}}))
                        data.push(...resources.unchecked.map((value) => {return {...value, type: type}}))
                        data.push(...resources.ignored.map((value) => {return {...value, type: type}}))
                    }
                }
                return data
            }))
        )
    }

    loadVsphereResourceDetails(resourceId: string){
        return this.httpClient.get<VsphereResourceDetails>(`${this.configService.config.apiUrl}/get-vsphere-resource-details?server=${this.serverId}&resource_id=${resourceId}`)
    }

    loadVsphereCharts(){
        return this.httpClient.get<VsphereChartsData>(`${this.configService.config.apiUrl}/list-vsphere-charts-data?server=${this.serverId}`)
    }
}
