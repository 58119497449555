<main>
  <mat-progress-bar *ngIf="loadingHttpReq || loadingRouterReq" [color]="loadingHttpReq ? 'accent': loadingRouterReq ? 'warn' : 'indeterminate'" mode="indeterminate"></mat-progress-bar>
  <app-sidenav class="layout" [profile]="profile$">
    <div class="container">
      <div class="header">
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container
          *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first">
          <img *ngIf="info" src="../assets/icons/home.svg">
          <ng-container *ngIf="!first">{{ "BREADCRUMB." + breadcrumb.replaceAll("-", "_") | uppercase | translate}}</ng-container>
        </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>arrow_right</mat-icon>
        </ng-template>
      </div>
      <router-outlet></router-outlet>
    </div>
  </app-sidenav>
</main>
