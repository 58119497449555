import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconSuffixName'
})
export class IconSuffixNamePipe implements PipeTransform {

  constructor(){
  }

  transform(active: boolean): string {
    if (active) {
      return "_white"
    }
    return ""
  }
}
