import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent {

  @Input()
  background = 'linear-gradient(90deg, #D8FFA6 0%, #82D695 100%)'

  @Input()
  title = ''

  @Input()
  img = ''

  @Input()
  value = {iac: '', cicd: ''}
}
