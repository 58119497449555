import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VsphereResourceTypeEnum } from '../enum/vsphere-resource-type.enum';

@Pipe({
  name: 'vsphereResourceTypeLabel'
})
export class VsphereResourceTypeLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService){

  }

  transform(type: string): string {
    const vsphereEnumKeys = Object.keys(VsphereResourceTypeEnum);
    if(vsphereEnumKeys.includes(type)){
      return this.translate.instant("VSPHERE_RESOURCES.TYPE." + type.toUpperCase().replaceAll("-", "_"))
    }
    return type
  }
}
