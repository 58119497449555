import { Injectable } from '@angular/core';
import { DateGranularityEnum } from '../enum/date-granularity.enum';
import { FinOpsContextConfig } from 'src/app/store/finops-context/finops-context.model';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

export type HistoricalDate = {
  previousDate: string,
  date: string
}

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private translate: TranslateService) {
  }

  public formatDate(date: any, format: string) {
    return dayjs(date).format(format)
  }

  public formatShortDate(date: any) {
    return this.formatDate(date, this.translate.instant('DATE.SHORT_FORMAT'))
  }

  public formatLongDate(date: any) {
    return this.formatDate(date, this.translate.instant('DATE.LONG_FORMAT'))
  }

  public formatYearDate(date: any) {
    return this.formatDate(date, this.translate.instant('DATE.YEAR_FORMAT'))
  }

  public formatMonthDate(date: any) {
    return this.formatDate(date, this.translate.instant('DATE.MONTH_FORMAT'))
  }

  public calculateYesterday(date: any) {
    return dayjs(date).subtract(1, 'day').toDate()
  }

  public calculateHistoricalDate(date: any, granularity: DateGranularityEnum, billingDay: boolean, depth: number) {
    let newDate: Date
    switch (granularity) {
      case DateGranularityEnum.year:
        newDate = dayjs(date).subtract(depth, 'year').toDate()
        if (billingDay) {
          newDate = dayjs(newDate).set('date', 1).set('month', 0).add(1, 'year').toDate()
        }
        return this.formatShortDate(newDate)

      case DateGranularityEnum.month:
        newDate = dayjs(date).subtract(depth, 'month').toDate()
        if (billingDay) {
          newDate = dayjs(newDate).set('date', 1).add(1, 'month').toDate()
        }
        return this.formatShortDate(newDate)

      case DateGranularityEnum.day:
        newDate = dayjs(date).subtract(depth, 'day').toDate()
        return this.formatShortDate(newDate)
    }
  }

  public getHistoricalDates(config: FinOpsContextConfig, depth: number) {
    let historicalDates: HistoricalDate[] = []
    for (let currentDepth = depth; currentDepth >= 0; currentDepth--) {
      historicalDates.push(
        {
          previousDate: this.calculateHistoricalDate(config.date, config.granularity, config.billingDay, currentDepth + 1),
          date: this.calculateHistoricalDate(config.date, config.granularity, config.billingDay, currentDepth)
        }
      )
    }
    return historicalDates
  }
}
