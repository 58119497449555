import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/core/config.service';
import { map } from "rxjs";
import { ISummaryResp } from './summary.model';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {

  constructor(private configService: ConfigService, private httpClient: HttpClient) {
  }

  getSummary() {
    return this.httpClient.get<ISummaryResp>(`${this.configService.config.apiUrl}/summarise-data`)
  }

}
