<div class="main" (click)="changeSelectedDate()" [ngStyle]="{background: getBackground((selectedDate$ | async)! == date)}" [title]="getTitle()">
    <div>
        <div class="date" [ngStyle]="{color: getForeground((selectedDate$ | async)! == date)}">{{getFormattedDate(date)}}</div>
        <div class="hours" [ngStyle]="{color: getForeground((selectedDate$ | async)! == date)}">{{counters[this.granularity].on_hours | number}} h</div>
        <div class="ratioOn" [ngStyle]="{color: getForeground((selectedDate$ | async)! == date)}">{{"TABLE.RATIO_ON" | translate}}: {{computeOnRatio(this.granularity) | number : '1.2-2'}}%</div>
        <div class="ratioOff" [ngStyle]="{color: getForeground((selectedDate$ | async)! == date)}">{{"TABLE.RATIO_OFF" | translate}}: {{computeOffRatio(this.granularity) | number : '1.2-2'}}%</div>
    </div>
    <app-on-off-gap 
        [counters]="counters" 
        [granularity]="granularity"
    />
</div>