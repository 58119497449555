import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/core/config.service";
import { Filter, FiltersInput } from "./filters.model";
import { ItemTypeEnum } from "src/app/shared/enum/item-type.enum";

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    constructor(private httpClient: HttpClient, private configService: ConfigService) {
    }

    putFiltersInput(filtersInput: FiltersInput) {
        let body = [
            {
                'type': filtersInput.filters_input_type,
                'name': filtersInput.filters_input_name,
                'filters': filtersInput.filters
            }
        ]
        return this.httpClient.put<FiltersInput[]>(
            `${this.configService.config.apiUrl}/filters-inputs`,
            body
        )
    }

    deleteFiltersInput(filtersInput: FiltersInput) {
        let body = [
            {
                'type': filtersInput.filters_input_type,
                'name': filtersInput.filters_input_name
            }
        ]
        return this.httpClient.delete<FiltersInput[]>(
            `${this.configService.config.apiUrl}/filters-inputs`,
            {
                body: body
            }
        )
    }

    listFiltersInputs() {
        return this.httpClient.get<FiltersInput[]>(
            `${this.configService.config.apiUrl}/filters-inputs`
        )
    }
}
