import { Pipe, PipeTransform } from '@angular/core';
import { ColorEnum } from '../enum/color.enum';

@Pipe({
  name: 'challengeColor'
})
export class ChallengeColorPipe implements PipeTransform {

  transform(challenge_ratio: number): string {
    if (challenge_ratio <= 30) {
      return ColorEnum.challenges_ratio_low;
    }
    if (challenge_ratio <= 60) {
      return ColorEnum.challenges_ratio_medium;
    }
    return ColorEnum.challenges_ratio_high;
  }
}
