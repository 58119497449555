export class LoadImpervaResources {
    static type = 'LoadImpervaResources'
    constructor() { }
}

export class LoadDataTableImpervaResources {
    static type = 'LoadDataTableImpervaResources'
    constructor() { }
}

export class UpdateCountersImpervaResources {
    static type = 'UpdateCountersImpervaResources'
    constructor() { }
}

export class ClearImpervaResource {
    static type = 'ClearImpervaResource'
    constructor() { }
}

export class SetImpervaResource {
    static type = 'SetImpervaResource'
    constructor(public payload: any) { }
}

export class LoadImpervaCharts {
    static type = 'LoadImpervaCharts'
    constructor() { }
}

export class LoadImpervaChartsFilteredData {
    static type = 'LoadImpervaChartsFilteredData'
    constructor() { }
}
