import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map } from "rxjs";
import { LoadingService } from "../service/loading.service";

@Injectable({
    providedIn: 'root'    
})
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(private loadingService: LoadingService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loadingService.setLoading(true, req.url);

        return next.handle(req)
            .pipe(
                catchError((err) => {
                    this.loadingService.setLoading(false, req.url)
                    return err
                })
            )
            .pipe(map<(HttpEvent<any> | any), any> ((evt: HttpEvent<any>) => {
                if (evt instanceof HttpResponse) {
                  this.loadingService.setLoading(false, req.url);
                }
                return evt;
              }));
    }

}