import { Injectable } from '@angular/core';
import {from} from "rxjs";
import {Auth} from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUser: any
  constructor() {
  }

  /** signin */
  public async signIn(): Promise<any> {
    try{
      const signIn =  await Auth.federatedSignIn()
      return signIn
    } catch(error){
      console.error(error)
      throw error
    }
  }

  /** get authenticate state */
  public async isAuthenticated(): Promise<boolean> {
    const user =  await Auth.currentAuthenticatedUser()
    this.currentUser = user
    return user
  }

  public getToken(){
    return from(Auth.currentSession())
  }

  /** signout */
  public async signOut(): Promise<any> {
    const result =  await Auth.signOut()
    return result
  }
}
