import { ExportsInput } from "./exports.model";

export class ListExportsInputs {
  static type = 'ListExportsInputs'
  constructor() { }
}

export class SaveExportsInput {
  static type = 'SaveExportsInput'
  constructor(public payload: ExportsInput) { }
}

export class DeleteExportsInput {
  static type = 'DeleteExportsInput'
  constructor(public payload: ExportsInput) { }
}
