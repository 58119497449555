export class LoadFinOpsAwsResourcesHistoricalData {
    static type = 'LoadFinOpsAwsResourcesHistoricalData'
    constructor() { }
}

export class LoadDataTableFinOpsAwsResources {
    static type = 'LoadDataTableFinOpsAwsResources'
    constructor() { }
}

export class ClearFinOpsAwsResource {
    static type = 'ClearFinOpsAwsResource'
    constructor() { }
}

export class SetFinOpsAwsResource {
    static type = 'SetFinOpsAwsResource'
    constructor(public payload: string) { }
}

export class LoadFinOpsAwsResourcesChartsData {
    static type = 'LoadFinOpsAwsResourcesChartsData'
    constructor() { }
}

export class LoadFinOpsAwsResourcesChartsFilteredData {
    static type = 'LoadFinOpsAwsResourcesChartsFilteredData'
    constructor() { }
}