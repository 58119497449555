import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ConfigService } from "src/app/core/config.service";
import { EntraIdChartsData, EntraIdResourceDetails, EntraIdResources } from "./entra-id-resources.model";
import { EntraIdResourceTypeEnum } from "../../shared/enum/entra-id-resource-type.enum";

@Injectable({
    providedIn: 'root'
})
export class EntraIdResourcesService {

    constructor(private httpClient: HttpClient, private configService: ConfigService) {

    }

    loadEntraIdResources(tenantKey: string) {
        return this.httpClient.get<EntraIdResources>(`${this.configService.config.apiUrl}/list-entra-id-resources-data?tenant=${tenantKey}`)
            .pipe(
                map((response => {
                    const data = []
                    const entraIdEnumKeys = Object.keys(EntraIdResourceTypeEnum);
                    for (let type in response) {
                        const resources = response[type as keyof EntraIdResources]
                        if (entraIdEnumKeys.includes(type)) {
                            data.push(...resources.checked.map((value) => { return { ...value, type: type } }))
                            data.push(...resources.unchecked.map((value) => { return { ...value, type: type } }))
                            data.push(...resources.ignored.map((value) => { return { ...value, type: type } }))
                        }
                    }
                    return data
                }))
            )
    }

    loadEntraIdResourceDetails(tenantKey: string, resourceId: string) {
        return this.httpClient.get<EntraIdResourceDetails>(`${this.configService.config.apiUrl}/get-entra-id-resource-details?tenant=${tenantKey}&resource_id=${resourceId}`)
    }

    loadEntraIdCharts(tenantKey: string) {
        return this.httpClient.get<EntraIdChartsData>(`${this.configService.config.apiUrl}/list-entra-id-charts-data?tenant=${tenantKey}`)
    }
}
