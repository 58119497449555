import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'ageLabel'
})
export class AgeLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService){
  }

  transform(age: number): string {
    if (age == 99999) {
      return "Unknown"
    }
    return age.toString()
  }
}
