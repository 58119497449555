import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ConfigService } from "src/app/core/config.service";
import { ImpervaChartsData, ImpervaResourceDetails, ImpervaResources } from "./imperva-resources.model";
import {ImpervaResourceTypeEnum} from "../../shared/enum/imperva-resource-type.enum";

@Injectable({
    providedIn: 'root'
})
export class ImpervaResourcesService {

    accountId = "2065392"

    constructor(private httpClient: HttpClient, private configService: ConfigService){

    }

    loadImpervaResources(){
        return this.httpClient.get<ImpervaResources>(`${this.configService.config.apiUrl}/list-imperva-resources-data?account=${this.accountId}`)
        .pipe(
            map((response=>{
                const data = []
                const impervaEnumKeys = Object.keys(ImpervaResourceTypeEnum);
                for(let type in response){
                    const resources = response[type as keyof ImpervaResources]
                    if(impervaEnumKeys.includes(type)){
                        data.push(...resources.checked.map((value) => {return {...value, type: type}}))
                        data.push(...resources.unchecked.map((value) => {return {...value, type: type}}))
                        data.push(...resources.ignored.map((value) => {return {...value, type: type}}))
                    }
                }
                return data
            }))
        )
    }

    loadImpervaResourceDetails(resourceId: string){
        return this.httpClient.get<ImpervaResourceDetails>(`${this.configService.config.apiUrl}/get-imperva-resource-details?account=${this.accountId}&resource_id=${resourceId}`)
    }

    loadImpervaCharts(){
        return this.httpClient.get<ImpervaChartsData>(`${this.configService.config.apiUrl}/list-imperva-charts-data?account=${this.accountId}`)
    }
}
