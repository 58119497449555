import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FinOpsCounters } from 'src/app/store/finops-aws-services/finops-aws-services.model';
import { DateGranularityEnum } from '../../enum/date-granularity.enum';

@Component({
  selector: 'app-finops-gap',
  templateUrl: './finops-gap.component.html',
  styleUrls: ['./finops-gap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinOpsGapComponent {

  @Input()
  sameLine: string = "false"

  @Input()
  granularity: string = DateGranularityEnum[DateGranularityEnum.month]

  @Input()
  counters: Record<string, FinOpsCounters> = {}

  getGapIconSrc() {
    if (this.counters[this.granularity].gap_ratio < 0) {
      return "assets/icons/gap_down.svg"
    }
    return "assets/icons/gap_up.svg"
  }

  getGapBackground() {
    if (this.counters[this.granularity].gap_ratio < 0) {
      return "var(--color_gap_down_background)"
    }
    return "var(--color_gap_up_background)"
  }

  getGapForeground() {
    if (this.counters[this.granularity].gap_ratio < 0) {
      return "var(--color_gap_down_text)"
    }
    return "var(--color_gap_up_text)"
  }

  getGapSign() {
    if (this.counters[this.granularity].gap_ratio < 0) {
      return
    }
    return "+"
  }
}
