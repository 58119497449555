import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gitlab-badge',
  templateUrl: './gitlab-badge.component.html',
  styleUrls: ['./gitlab-badge.component.scss']
})
export class GitlabBadgeComponent {

  @Input()
  badgeImageUrl = ''

  @Input()
  badgeLinkUrl = ''
}
