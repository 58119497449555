import { Action, Selector, State, StateContext, StateToken, Store } from "@ngxs/store";
import { Filter, FiltersStateModel } from "./filters.model";
import { Injectable } from "@angular/core";
import { append, patch, removeItem } from "@ngxs/store/operators";
import { AddFilter, RemoveFilter, ResetFilters, ListFiltersInputs, DeleteFiltersInput, SaveFiltersInput, SetFilters, InitFilters } from "./filters.actions";
import { FilterService } from "./filters.service";
import { tap } from "rxjs";
import { ItemTypeEnum } from "src/app/shared/enum/item-type.enum";

const FILTERS_TOKEN: StateToken<FiltersStateModel> = new StateToken<FiltersStateModel>('filters')

const FiltersStateModelDefault: FiltersStateModel = {
  filtersByType: {},
  filtersInputs: []
}

@State<FiltersStateModel>({
  name: FILTERS_TOKEN,
  defaults: FiltersStateModelDefault
})

@Injectable()
export class FiltersState {
  constructor(
    private filterService: FilterService
  ) {
  }

  @Selector()
  static getFiltersByType(state: FiltersStateModel) {
    return state.filtersByType
  }

  @Selector()
  static getFiltersInputs(state: FiltersStateModel) {
    return state.filtersInputs;
  }

  @Action(InitFilters)
  initFilters(ctx: StateContext<FiltersStateModel>) {
    let filtersByType: Record<string, Filter[]> = {}
    Object.values(ItemTypeEnum).forEach(type => {
      if (isNaN(Number(type))) {
        filtersByType[type] = []
      }
    });
    ctx.setState(
      patch<FiltersStateModel>({
        filtersByType: filtersByType
      })
    )
  }

  @Action(AddFilter)
  addFilter(ctx: StateContext<FiltersStateModel>, payload: any) {
    let filtersByType = ctx.getState().filtersByType
    let newFiltersByType: Record<string, Filter[]> = {}
    Object.keys(filtersByType).forEach(filterKey => {
      let newFilters: Filter[] = []
      filtersByType[filterKey].forEach(filter => {
        newFilters.push(filter)
      });
      if (filterKey == ItemTypeEnum[payload.payload]) {
        newFilters = newFilters.filter(filter => filter.key != payload.filter.key);
        newFilters.push(payload.filter)
      }
      newFiltersByType[filterKey] = newFilters
    });
    ctx.setState(
      patch<FiltersStateModel>({
        filtersByType: newFiltersByType
      })
    )
  }

  @Action(RemoveFilter)
  removeFilter(ctx: StateContext<FiltersStateModel>, payload: any) {
    let filtersByType = ctx.getState().filtersByType
    let newFiltersByType: Record<string, Filter[]> = {}
    Object.keys(filtersByType).forEach(filterKey => {
      let newFilters: Filter[] = []
      filtersByType[filterKey].forEach(filter => {
        newFilters.push(filter)
      });
      if (filterKey == ItemTypeEnum[payload.payload]) {
        newFilters = newFilters.filter(filter => filter.key != payload.filterKey);
      }
      newFiltersByType[filterKey] = newFilters
    });
    ctx.setState(
      patch<FiltersStateModel>({
        filtersByType: newFiltersByType
      })
    )
  }

  @Action(SetFilters)
  setFilters(ctx: StateContext<FiltersStateModel>, payload: any) {
    let filtersByType = ctx.getState().filtersByType
    let newFiltersByType: Record<string, Filter[]> = {}
    Object.keys(filtersByType).forEach(filterKey => {
      let newFilters: Filter[] = []
      if (filterKey == ItemTypeEnum[payload.payload]) {
        newFilters = payload.filters;
      } else {
        filtersByType[filterKey].forEach(filter => {
          newFilters.push(filter)
        });
      }
      newFiltersByType[filterKey] = newFilters
    });
    ctx.setState(
      patch<FiltersStateModel>({
        filtersByType: newFiltersByType
      })
    )
  }

  @Action(ResetFilters)
  resetFilters(ctx: StateContext<FiltersStateModel>, payload: any) {
    let filtersByType = ctx.getState().filtersByType
    let newFiltersByType: Record<string, Filter[]> = {}
    Object.keys(filtersByType).forEach(filterKey => {
      let newFilters: Filter[] = []
      if (filterKey != ItemTypeEnum[payload.payload]) {
        filtersByType[filterKey].forEach(filter => {
          newFilters.push(filter)
        });
      }
      newFiltersByType[filterKey] = newFilters
    });
    ctx.setState(
      patch<FiltersStateModel>({
        filtersByType: newFiltersByType
      })
    )
  }

  @Action(ListFiltersInputs)
  listFiltersInputs(ctx: StateContext<FiltersStateModel>) {
    return this.filterService.listFiltersInputs().pipe(
      tap(value => {
        return ctx.patchState({
          filtersInputs: value
        })
      })
    )
  }

  @Action(SaveFiltersInput)
  saveFiltersInput(ctx: StateContext<FiltersStateModel>, payload: any) {
    return this.filterService.putFiltersInput(payload.payload).pipe(
      tap(value => {
        return ctx.patchState({
          filtersInputs: value
        })
      })
    )
  }

  @Action(DeleteFiltersInput)
  deleteFiltersInput(ctx: StateContext<FiltersStateModel>, payload: any) {
    return this.filterService.deleteFiltersInput(payload.payload).pipe(
      tap(value => {
        return ctx.patchState({
          filtersInputs: value
        })
      })
    )
  }
}
