export const SUMMARY_STORAGE_KEY = 'summarise.summary'

export type ComplianceState = {
  not_compliant: string,
  compliant: string,
  ignored: string
}

export type Measure = {
  compliant_ratio: string
  compliant_states: ComplianceState,
  total_not_ignored_projects: string
}

export type ComplianceSource = {
  compliance_sources: {
    cloud_formation: string,
    none: string,
    terraform: string
  }
}

export type Challenge = {
  challenge_ratio: string,
  challenge_type: string,
  challenge_target: string,
  challenge_goal: number,
  challenge_current: number,
  actions_count: number,
  projects_count: number
}

export type IacMeasure = {
  measure: Partial<Measure> & Partial<ComplianceSource>
}

export type CicdMeasure = {
  measure: Partial<Measure>
}

export interface ISummaryResp {
  "cicd-challenges": Challenge[],
  "iac-challenges": Challenge[],
  "finops-aws-challenges": Challenge[],
  "iac_last_measure": {
    measure: {
      compliant_ratio: string,
      compliant_sources: {
        cloud_formation: string,
        none: string,
        terraform: string
      },
      compliant_states: {
        compliant: string,
        ignored: string,
        not_compliant: string
      },
      total_not_ignored_projects: string,
      total_projects: string
    },
  },
  "cicd_last_measure": {
    measure: {
      compliant_ratio: string,
      compliant_states: {
        compliant: string,
        ignored: string,
        not_compliant: string
      },
      total_not_ignored_projects: string,
      total_projects: string,
    },
  },
  "new_objective": string
}

export class SummaryStateModel {
  constructor(
    public cicd_last_measure: CicdMeasure,
    public iac_last_measure: IacMeasure,
    public cicd_challenges: Challenge[],
    public iac_challenges: Challenge[],
    public finops_aws_challenges: Challenge[],
    public new_objective: string) { }
}