import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OnOffCounters } from 'src/app/store/finops-aws-on-off/finops-aws-on-off.model';
import { SetFinOpsContextSelectedDate } from 'src/app/store/finops-context/finops-context.actions';
import { FinOpsContextState } from 'src/app/store/finops-context/finops-context.state';
import { TranslateService } from '@ngx-translate/core';
import { DateGranularityEnum } from '../../enum/date-granularity.enum';
import { DateService } from '../../service/date.service';

@Component({
  selector: 'app-on-off-date',
  templateUrl: './on-off-date.component.html',
  styleUrls: ['./on-off-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnOffDateComponent {

  @Select(FinOpsContextState.getSelectedDate) selectedDate$!: Observable<string>;

  @Input()
  date = ''

  @Input()
  granularity: string = DateGranularityEnum[DateGranularityEnum.month]

  @Input()
  counters: Record<string, OnOffCounters> = {}

  constructor(
    private store: Store,
    private translate: TranslateService,
    private dateService: DateService,
    private decimalPipe: DecimalPipe
  ) { }

  computeOnRatio(a_granularity: string) {
    let on_off_hours = this.counters[a_granularity].on_hours + this.counters[a_granularity].off_hours
    if (on_off_hours == 0) {
      return 100.0
    }
    return this.counters[a_granularity].on_hours / on_off_hours * 100.0
  }

  computeOffRatio(a_granularity: string) {
    let on_off_hours = this.counters[a_granularity].on_hours + this.counters[a_granularity].off_hours
    if (on_off_hours == 0) {
      return 100.0
    }
    return this.counters[a_granularity].off_hours / on_off_hours * 100.0
  }

  getBackground(selected: boolean) {
    if (this.counters[this.granularity].prediction) {
      if (selected) {
        return 'var(--color_button_stripped_background_selected)'
      }
      return 'var(--color_button_stripped_background_unselected)'
    }
    if (selected) {
      return 'var(--color_button_background_selected)'
    }
    return 'var(--color_button_background_unselected)'
  }

  getForeground(selected: boolean) {
    if (selected) {
      return 'var(--color_button_text_selected)'
    }
    return 'var(--color_button_text_unselected)'
  }

  getFormattedDate(date: string) {
    let config = this.store.selectSnapshot(FinOpsContextState.getConfig)
    switch (this.granularity) {
      case DateGranularityEnum[DateGranularityEnum.year]:
        if (config.billingDay) {
          const yesterday = this.dateService.calculateYesterday(date)
          return this.dateService.formatYearDate(yesterday)
        }
        return this.dateService.formatYearDate(date)
      case DateGranularityEnum[DateGranularityEnum.month]:
        if (config.billingDay) {
          const yesterday = this.dateService.calculateYesterday(date)
          return this.dateService.formatMonthDate(yesterday)
        }
        return this.dateService.formatMonthDate(date)
      case DateGranularityEnum[DateGranularityEnum.day]:
        return this.dateService.formatLongDate(date)
    }
    return date
  }

  getTitle() {
    let config = this.store.selectSnapshot(FinOpsContextState.getConfig)
    let title = "";
    if (config.billingDay) {
      title = this.translate.instant("FINOPS.BILLING_DAY", {
        value: this.dateService.formatLongDate(this.date)
      })
    } else {
      title = this.translate.instant("FINOPS.DAY", {
        value: this.dateService.formatLongDate(this.date)
      })
    }
    if (this.counters[this.granularity].prediction) {
      title += "\n\n"
      title += this.translate.instant("FINOPS.PREDICTION")
    }
    Object.entries(this.counters).forEach(entries => {
      let granularity = entries[0]
      let counters = entries[1]
      title += "\n\n" + this.translate.instant("FINOPS.COUNTERS", {
        granularity: granularity
      })
      title += "\n" + this.translate.instant("FINOPS.DAY_RATIO", {
        value: this.decimalPipe.transform(counters.day_ratio, "1.2-2")
      })
      title += "\n" + this.translate.instant("FINOPS.PREVIOUS_ON", {
        value: this.decimalPipe.transform(counters.previous_on_hours)
      })
      title += "\n" + this.translate.instant("FINOPS.ON", {
        value: this.decimalPipe.transform(counters.on_hours),
        ratio: this.decimalPipe.transform(this.computeOnRatio(granularity), "1.2-2")
      })
      title += "\n" + this.translate.instant("FINOPS.OFF", {
        value: this.decimalPipe.transform(counters.off_hours),
        ratio: this.decimalPipe.transform(this.computeOffRatio(granularity), "1.2-2")
      })
      title += "\n" + this.translate.instant("FINOPS.GAP_ON", {
        value: this.decimalPipe.transform(counters.gap_value),
        ratio: this.decimalPipe.transform(counters.gap_ratio, "1.2-2")
      })
    });
    return title
  }

  changeSelectedDate() {
    this.store.dispatch([new SetFinOpsContextSelectedDate(this.date)])
  }
}
