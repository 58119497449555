import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {authGuard} from "./core/auth/auth.guard";
import { appResolver } from './app.resolver';
import { profileResolver } from './profile.resolver';

export const routes: Routes = [
  {
    path:'',
    canActivate: [authGuard],
    data: {
      breadcrumb: 
      { 
        info: 'home'
      }
    },
    resolve: {app: appResolver, profile: profileResolver},
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'iac',
        loadChildren: () => import('./iac/iac.module').then(m => m.IacModule),
      },
      {
        path: 'cicd',
        loadChildren: () => import('./cicd/cicd.module').then(m => m.CicdModule),
      },
      {
        path: 'challenges',
        loadChildren: () => import('./challenge/challenge.module').then(m => m.ChallengeModule),
      },
      {
        path: 'finops',
        loadChildren: () => import('./finops/finops.module').then(m => m.FinOpsModule),
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
