import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken } from "@ngxs/store";
import {
    SetFinOpsContextConfig,
    SetFinOpsContextSelectedOrganization,
    SetFinOpsContextSelectedAccount,
    SetFinOpsContextSelectedDate
} from "./finops-context.actions";
import { FinOpsContextStateModel } from "./finops-context.model";
import { DateGranularityEnum } from "src/app/shared/enum/date-granularity.enum";
import { AwsService } from "src/app/shared/service/aws.service";

const FINOPS_TOKEN: StateToken<FinOpsContextStateModel> = new StateToken('finOpsContext');

const FinOpsContextStateModelDefaults: FinOpsContextStateModel = {
    config: {
        date: new Date(),
        granularity: DateGranularityEnum.month,
        billingDay: false,
        depth: 3
    },
    organizations: AwsService.listOrganizations(),
    selectedDate: "",
    selectedAccount: '850062263036',
    selectedOrganization: {
        name: 'europe',
        accounts: AwsService.getOrganizationAccounts(AwsService.ORGANIZATION_EUROPE)
    }
}

@State<FinOpsContextStateModel>({
    name: FINOPS_TOKEN,
    defaults: FinOpsContextStateModelDefaults
})

@Injectable()
export class FinOpsContextState {
    @Selector()
    static getConfig(state: FinOpsContextStateModel) {
        return state.config
    }

    @Selector()
    static getSelectedDate(state: FinOpsContextStateModel) {
        return state.selectedDate
    }

    @Selector()
    static getOrganizations(state: FinOpsContextStateModel) {
        return state.organizations
    }

    @Selector()
    static getSelectedOrganization(state: FinOpsContextStateModel) {
        return state.selectedOrganization
    }

    @Selector()
    static getSelectedAccount(state: FinOpsContextStateModel) {
        return state.selectedAccount
    }

    @Action(SetFinOpsContextConfig)
    setFinOpsContextConfig(ctx: StateContext<FinOpsContextStateModel>, payload: any) {
        ctx.patchState({
            selectedDate: "",
            config: payload.payload
        })
    }

    @Action(SetFinOpsContextSelectedDate)
    setFinOpsContextSelectedDate(ctx: StateContext<FinOpsContextStateModel>, payload: any) {
        ctx.patchState({
            selectedDate: payload.payload
        })
    }

    @Action(SetFinOpsContextSelectedOrganization)
    setOrganization(ctx: StateContext<FinOpsContextStateModel>, payload: any) {
        const organization = ctx.getState().organizations.filter(organization => {
            return organization.name === payload.payload
        })
        return ctx.patchState({
            selectedOrganization: organization[0],
            selectedAccount: organization[0].accounts[0]
        })
    }

    @Action(SetFinOpsContextSelectedAccount)
    setAccount(ctx: StateContext<FinOpsContextStateModel>, payload: any) {
        const account = ctx.getState().selectedOrganization.accounts.filter(
            account => {
                return account === payload.payload
            }
        )
        return ctx.patchState(
            {
                selectedAccount: account[0]
            }
        )
    }
}
