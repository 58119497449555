import { Pipe, PipeTransform } from '@angular/core';
import { CompliancyColorPipe } from './compliancy-color.pipe';

@Pipe({
  name: 'statusColor'
})
export class StatusColorPipe implements PipeTransform {

  constructor(private compliancyColorPipe: CompliancyColorPipe) {
  }

  transform(status: string): string {
    switch (status) {
      case 'failed':
        return this.compliancyColorPipe.transform("not_compliant");
      default:
        return this.compliancyColorPipe.transform("compliant");
    }
  }
}
