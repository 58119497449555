export class LoadAwsResources {
    static type = 'LoadAwsResources'
    constructor() { }
}

export class SetAccount {
    static type = 'SetAccount'
    constructor(public payload: any) { }
}

export class LoadDataTableAwsResources {
    static type = 'LoadDataTableAwsResources'
    constructor() { }
}

export class UpdateCountersAwsResources {
    static type = 'UpdateCountersAwsResources'
    constructor() { }
}

export class SetOrganization {
    static type = 'SetOrganization'
    constructor(public payload: any) {
    }
}

export class ClearAwsResource {
    static type = 'ClearAwsResource'
    constructor() { }
}

export class SetAwsResource {
    static type = 'SetAwsResource'
    constructor(public payload: any) { }
}

export class LoadAwsCharts {
    static type = 'LoadAwsCharts'
    constructor() { }
}

export class LoadAwsChartsFilteredData {
    static type = 'LoadAwsChartsFilteredData'
    constructor() { }
}
