import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { TranslateService } from '@ngx-translate/core';
import { ColorEnum } from '../../enum/color.enum';
import { v4 as uuidv4 } from 'uuid';

export type FinOpsChartHistoricalItem = {
  date: Date,
  cost: number,
  dateString: string,
  predictionString: string
}

export type FinOpsChartHistoricalData = {
  minCostRatioString: string,
  minCostValueString: string,
  prediction: boolean,
  costs: FinOpsChartHistoricalItem[]
}

@Component({
  selector: 'app-chart-finops-historical',
  templateUrl: './chart-finops-historical.component.html',
  styleUrls: ['./chart-finops-historical.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartFinOpsHistoricalComponent implements AfterViewInit {

  @Input() public chartWidth: string = "100%";

  @Input() public chartHeight: string = "500px";

  private chart: any;

  public chartDivId = uuidv4();

  public chartTitle = "";

  public prediction = false;

  /**
   * Constructor
   */
  constructor(
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  /**
   * Clean up charts
   */
  public cleanUp() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  /**
   * Refresh the data
   */
  public refresh(data: FinOpsChartHistoricalData) {
    this.chart.data = data.costs;
    this.chartTitle = this.translate.instant("CHARTS.HISTORICAL_COSTS");
    if (data.minCostRatioString != "") {
      this.chartTitle += " > $" + data.minCostValueString + " (" + data.minCostRatioString + "%)"
    }
    this.prediction = data.prediction
    this.changeDetectorRef.detectChanges();
  }

  getChartStyle() {
    return "width: " + this.chartWidth + " ; height: " + this.chartHeight
  }

  ngAfterViewInit(): void {
    // Chart code goes in here
    am4core.useTheme(am4themes_animated);
    let seriesColor = am4core.color(ColorEnum.charts_default);

    this.chart = am4core.create(this.chartDivId, am4charts.XYChart);

    this.chart.paddingRight = 20;

    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.minZoomCount = 3;
    dateAxis.dateFormats.setKey("month", "yyyy MMM, dd")

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    let series = this.chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "cost";
    series.strokeWidth = 2;
    series.tooltipText = "{predictionString}" + this.translate.instant("CHARTS.FIELD_DATE") + " {dateString}\n" + this.translate.instant("CHARTS.FIELD_COST") + " ${valueY}";
    series.name = "Historical";
    series.stroke = seriesColor;
    series.fill = series.stroke;

    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.xAxis = dateAxis;

    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    this.chart.scrollbarX = scrollbarX;
  }
}
