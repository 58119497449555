import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { ProfileStoreModel } from './store/profile/profile.model';
import { LoadProfile } from './store/profile/profile.actions';
import { take } from 'rxjs';

export const profileResolver: ResolveFn<ProfileStoreModel> = (route, state, store: Store = inject(Store)) => {
  return store.dispatch(LoadProfile)
  .pipe(
    take(1)
  )
};
