import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'awsMonitoringMetric'
})
export class AwsMonitoringMetricPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {
  }

  transform(metric: any, valueKey: string): string {
    if (metric === undefined || metric['UNIT'] === undefined || metric[valueKey] === undefined) {
      return this.translate.instant('FINOPS.NO_DATA')
    }
    let unit: string = metric['UNIT']
    let numberValue: number = +metric[valueKey]
    if (unit == 'Count') {
      return numberValue.toString()
    }
    if (unit == 'Percent') {
      return numberValue + "%"
    }
    if (unit == 'Bytes') {
      let unitText = "Bytes"
      let unitRatio = 1
      if (Math.abs(numberValue) >= 1000000000000000) {
        unitText = 'PB'
        unitRatio = 1000000000000000
      } else if (Math.abs(numberValue) >= 1000000000000) {
        unitText = 'TB'
        unitRatio = 1000000000000
      } else if (Math.abs(numberValue) >= 1000000000) {
        unitText = 'GB'
        unitRatio = 1000000000
      } else if (Math.abs(numberValue) >= 1000000) {
        unitText = 'MB'
        unitRatio = 1000000
      } else if (Math.abs(numberValue) >= 1000) {
        unitText = 'KB'
        unitRatio = 1000
      }
      return Math.trunc(numberValue / unitRatio) + " " + unitText
    }
    return numberValue + " " + unit
  }
}
