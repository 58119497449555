import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'archivedLabel'
})
export class ArchivedLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService){

  }

  transform(archived: boolean): string {
    if (archived) {
      return this.translate.instant("ARCHIVED.TRUE")
    }
    return this.translate.instant("ARCHIVED.FALSE")
  }
}
