import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AwsService {

  constructor() { }

  static readonly ORGANIZATION_EUROPE = 'EUROPE'
  static readonly ORGANIZATION_OTHER = 'OTHER'

  public static listOrganizations() {
    return [
      {
        name: this.ORGANIZATION_EUROPE,
        accounts: [
          '850062263036',
          '506790841740',
          '609778184017'
        ]
      },
      {
        name: this.ORGANIZATION_OTHER,
        accounts: [
          '082172913265',
          '793594303043',
          '073326253004',
          '263506692482',
          '158108687327',
          '931733121667',
          '041796619890'
        ]
      }
    ]
  }

  public static getOrganization(accountId: string) {
    let result: string = ''
    this.listOrganizations().forEach(organization => {
      if (organization.accounts.includes(accountId)) {
        result = organization.name
      }
    });
    return result
  }

  public static getOrganizationAccounts(organizationName: string) {
    let result: string[] = []
    this.listOrganizations().forEach(organization => {
      if (organization.name == organizationName) {
        result = organization.accounts;
      }
    });
    return result
  }

  public static listAccounts() {
    let result: string[] = []
    this.listOrganizations().forEach(organization => {
      organization.accounts.forEach(accountId => {
        result.push(accountId)
      });
    });
    return result
  }
}
