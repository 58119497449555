import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { DateGranularityEnum } from '../../enum/date-granularity.enum';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FinOpsContextState } from 'src/app/store/finops-context/finops-context.state';
import { SetFinOpsContextConfig } from 'src/app/store/finops-context/finops-context.actions';
import { FinOpsContextConfig } from 'src/app/store/finops-context/finops-context.model';
import { Router } from '@angular/router';
import { MatDateFormats, MAT_NATIVE_DATE_FORMATS, MAT_DATE_FORMATS } from '@angular/material/core';

export const GRI_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short'
    } as Intl.DateTimeFormatOptions
  }
}

@Component({
  selector: 'app-finops-config',
  templateUrl: './finops-config.component.html',
  styleUrls: ['./finops-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS}
  ]
})
export class FinOpsConfigComponent implements OnInit {

  @Input()
  callbackFunction!: () => void

  DateGranularityEnum = DateGranularityEnum

  maxDate: Date = new Date();

  selectedDate: Date = this.maxDate;
  selectedGranularity: DateGranularityEnum = DateGranularityEnum.month;
  selectedBillingDay: boolean = false
  selectedDepth: number = 3

  authorizedDepths: number[] = [];

  constructor(
    private store: Store,
    private router: Router
  ) {
    for (let depth = 1; depth <= 99; depth++) {
      this.authorizedDepths.push(depth);
    }
  }

  ngOnInit(): void {
    let config: FinOpsContextConfig = this.store.selectSnapshot(FinOpsContextState.getConfig)
    this.selectedDate = config.date
    this.selectedGranularity = config.granularity
    this.selectedBillingDay = config.billingDay
    this.selectedDepth = config.depth
  }

  selectDate(event: MatDatepickerInputEvent<Date>) {
    this.selectedDate = event.value!;
    this.updateConfig();
  }

  selectGranularityDay = (): void => {
    this.selectedGranularity = DateGranularityEnum.day;
    this.updateConfig();
  }

  selectGranularityMonth = (): void => {
    this.selectedGranularity = DateGranularityEnum.month;
    this.updateConfig();
  }

  selectGranularityYear = (): void => {
    this.selectedGranularity = DateGranularityEnum.year;
    this.updateConfig();
  }

  selectBillingDay(selected: boolean) {
    this.selectedBillingDay = selected;
    this.updateConfig();
  }

  selectDepth(depth: number) {
    this.selectedDepth = depth;
    this.updateConfig();
  }

  updateConfig() {
    this.store.dispatch(new SetFinOpsContextConfig({
      date: this.selectedDate,
      granularity: this.selectedGranularity,
      billingDay: this.selectedBillingDay,
      depth: this.selectedDepth
    }))
    this.callbackFunction()
  }

  isHistoricalChartsRouting() {
    return this.router.url.endsWith('/accounts/charts') || this.router.url.endsWith('/services/charts') || this.router.url.endsWith('/resources/charts')
  }

  isGranularityRouting() {
    return this.router.url.includes('/finops/') && !this.router.url.endsWith('/on-off/charts')
  }
}
