export class LoadOnOffHistoricalData {
    static type = 'LoadOnOffHistoricalData'
    constructor() { }
}

export class LoadDataTableOnOffResources {
    static type = 'LoadDataTableOnOffResources'
    constructor() { }
}

export class LoadOnOffChartsData {
    static type = 'LoadOnOffChartsData'
    constructor() { }
}

export class LoadOnOffChartsFilteredData {
    static type = 'LoadOnOffChartsFilteredData'
    constructor() { }
}