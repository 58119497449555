export enum IacProjectTypeEnum {
  'europe',
  'usa',
  'imperva',
  'vsphere',
  'entra_id',
  'gcp',
  'training',
  'module',
  'other'
}
