export class LoadFinOpsAwsServicesHistoricalData {
    static type = 'LoadFinOpsAwsServicesHistoricalData'
    constructor() { }
}

export class LoadDataTableFinOpsAwsServices {
    static type = 'LoadDataTableFinOpsAwsServices'
    constructor() { }
}

export class LoadFinOpsAwsServicesChartsData {
    static type = 'LoadFinOpsAwsServicesChartsData'
    constructor() { }
}

export class LoadFinOpsAwsServicesChartsFilteredData {
    static type = 'LoadFinOpsAwsServicesChartsFilteredData'
    constructor() { }
}
