import { Component, Input, numberAttribute, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from "../../../core/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from 'rxjs';
import { User } from 'src/app/store/profile/profile.model';

export interface Menu {
  path: string,
  label: string,
  isActive: boolean,
  icon: string,
  children?: Menu[]
}
import { Challenge } from 'src/app/store/summary/summary.model';
import { Select } from '@ngxs/store';
import { SummaryState } from 'src/app/store/summary/summary.state';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadData } from 'src/app/store/summary/summary.actions';
import { LoadProfile } from 'src/app/store/profile/profile.actions';
import { SubscriptionService } from '../../service/subscription.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  @Select(SummaryState.getAllChallenges) challenges$ !: Observable<Challenge[]>

  isChallengesLoaded: boolean = false

  @Input()
  profile: Observable<User> = new Observable();

  @Input()
  profileImg: any = ''

  protected readonly numberAttribute = numberAttribute;

  challenger = false
  challengeMenuAdded = false

  links: Menu[] = [
    {
      path: '/home',
      label: this.translate.instant("SIDENAV.HOME"),
      isActive: false,
      icon: 'home',
    },
    {
      path: '/iac',
      label: this.translate.instant('SIDENAV.IAC'),
      icon: 'iac',
      isActive: false,
      children: [
        {
          path: '/iac/projects',
          label: this.translate.instant('SIDENAV.PROJECTS'),
          icon: 'iac',
          isActive: false,
          children: [
            {
              path: '/iac/projects/iac-gitlab',
              label: this.translate.instant('SIDENAV.GITLAB'),
              icon: 'iac',
              isActive: false,
            }
          ]
        },
        {
          path: '/iac/resources',
          label: this.translate.instant('SIDENAV.RESOURCES'),
          icon: 'iac',
          isActive: false,
          children: [
            {
              path: '/iac/resources/aws',
              label: this.translate.instant('SIDENAV.AWS'),
              icon: 'iac',
              isActive: false,
            },
            {
              path: '/iac/resources/entraid',
              label: this.translate.instant('SIDENAV.ENTRA_ID'),
              icon: 'iac',
              isActive: false,
            },
            {
              path: '/iac/resources/gcp',
              label: this.translate.instant('SIDENAV.GCP'),
              icon: 'iac',
              isActive: false,
            },
            {
              path: '/iac/resources/imperva',
              label: this.translate.instant('SIDENAV.IMPERVA'),
              icon: 'iac',
              isActive: false,
            },
            {
              path: '/iac/resources/vsphere',
              label: this.translate.instant('SIDENAV.VSPHERE'),
              icon: 'iac',
              isActive: false,
            }
          ]
        },
        {
          path: '/iac/factorisation',
          label: this.translate.instant('SIDENAV.FACTORISATION'),
          icon: 'iac',
          isActive: false,
          children: [
            {
              path: '/iac/factorisation/tfmodules',
              label: this.translate.instant('SIDENAV.TF_MODULES'),
              icon: 'iac',
              isActive: false,
            }
          ]
        }
      ]
    },
    {
      path: '/cicd',
      label: this.translate.instant('SIDENAV.CICD'),
      icon: 'cicd',
      isActive: false,
      children: [
        {
          path: '/cicd/projects',
          label: this.translate.instant('SIDENAV.PROJECTS'),
          icon: 'cicd',
          isActive: false,
          children: [
            {
              path: '/cicd/projects/cicd-gitlab',
              label: this.translate.instant('SIDENAV.GITLAB'),
              icon: 'cicd',
              isActive: false,
            }
          ]
        },
        {
          path: '/cicd/factorisation',
          label: this.translate.instant('SIDENAV.FACTORISATION'),
          icon: 'cicd',
          isActive: false,
          children: [
            {
              path: '/cicd/factorisation/templates',
              label: this.translate.instant('SIDENAV.TEMPLATES'),
              icon: 'cicd',
              isActive: false,
            },
            {
              path: '/cicd/factorisation/libraries',
              label: this.translate.instant('SIDENAV.LIBRARIES'),
              icon: 'cicd',
              isActive: false,
            },
            {
              path: '/cicd/factorisation/docker-images',
              label: this.translate.instant('SIDENAV.DOCKER_IMAGES'),
              icon: 'cicd',
              isActive: false,
            }
          ]
        }
      ]
    },
    {
      path: '/finops',
      label: this.translate.instant('SIDENAV.FINOPS'),
      icon: 'finops',
      isActive: false,
      children: [
        {
          path: '/finops/aws',
          label: this.translate.instant('SIDENAV.AWS'),
          icon: 'finops',
          isActive: false,
          children: [
            {
              path: '/finops/aws/accounts',
              label: this.translate.instant('SIDENAV.ACCOUNTS'),
              icon: 'finops',
              isActive: false,
            },
            {
              path: '/finops/aws/services',
              label: this.translate.instant('SIDENAV.SERVICES'),
              icon: 'finops',
              isActive: false,
            },
            {
              path: '/finops/aws/resources',
              label: this.translate.instant('SIDENAV.RESOURCES'),
              icon: 'finops',
              isActive: false,
            },
            {
              path: '/finops/aws/on-off',
              label: this.translate.instant('SIDENAV.ON_OFF'),
              icon: 'finops',
              isActive: false,
            }
          ]
        }
      ]
    }
  ]

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private auth: AuthService,
    private subscriptionsService: SubscriptionService) {
  }

  logOut = (): void => {
    this.auth.signOut();
  }

  ngOnInit(): void {
    this.subscriptionsService.subscribeActionSuccessful(
      this.constructor.name,
      LoadData,
      () => {
        this.isChallengesLoaded = true
      }
    )
    this.subscriptionsService.subscribeActionSuccessful(
      this.constructor.name,
      LoadProfile,
      () => {
        if (this.challenger && !this.challengeMenuAdded) {
          this.challengeMenuAdded = true
          this.links.splice(1, 0, {
            path: '/challenges',
            label: this.translate.instant('SIDENAV.CHALLENGES'),
            isActive: false,
            icon: 'challenges'
          })
        }
      }
    )
    this.subscriptionsService.subscribeObserver(
      this.constructor.name,
      this.profile,
      value => {
        this.challenger = value.challenger
      }
    )
  }

  ngOnDestroy() {
    this.subscriptionsService.unsubscribeAll(this.constructor.name)
  }

  goChallenges(): void {
    this.router.navigate(["challenges"], { relativeTo: this.activatedRoute.parent })
  }

  goUserGuide = (): void => {
    window.open('https://docs.google.com/document/d/1hWdB6U8gaLh8OF8ZXoUZuo1NbySfz52lAOS8LMZmXCw/view', '_blank')
  }
}
