export class LoadEntraIdResources {
    static type = 'LoadEntraIdResources'
    constructor() { }
}

export class LoadDataTableEntraIdResources {
    static type = 'LoadDataTableEntraIdResources'
    constructor() { }
}

export class UpdateCountersEntraIdResources {
    static type = 'UpdateCountersEntraIdResources'
    constructor() { }
}

export class ClearEntraIdResource {
    static type = 'ClearEntraIdResource'
    constructor() { }
}

export class SetEntraIdResource {
    static type = 'SetEntraIdResource'
    constructor(public payload: any) { }
}

export class LoadEntraIdCharts {
    static type = 'LoadEntraIdCharts'
    constructor() { }
}

export class LoadEntraIdChartsFilteredData {
    static type = 'LoadEntraIdChartsFilteredData'
    constructor() { }
}

export class SetTenant {
    static type = 'SetTenant'
    constructor(public payload: any) { }
}
