import { Pipe, PipeTransform } from '@angular/core';
import { ColorEnum } from '../enum/color.enum';

@Pipe({
  name: 'compliancyColor'
})
export class CompliancyColorPipe implements PipeTransform {

  transform(compliancy: string): string {
    switch (compliancy) {
      case 'compliant':
      case 'Compliant':
        return ColorEnum.compliant;
      case 'not_compliant':
      case 'Not compliant':
        return ColorEnum.not_compliant;
      case 'ignored':
        return ColorEnum.compliant_none;
      default:
        return ''
    }
  }
}
