import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/core/config.service";
import { TfModulesData} from "./tfmodules.model";
import { map } from "rxjs";
import { IacProjectTypeEnum } from "src/app/shared/enum/iac-project-type.enum";
import { IacProjects } from "../iac-projects/iac-projects.model";

@Injectable({
    providedIn: 'root'
})
export class TfModulesService {

    constructor(private httpClient: HttpClient, private configService: ConfigService){

    }

    loadTfModulesData(){
        return this.httpClient.get<TfModulesData>(`${this.configService.config.apiUrl}/list-terraform-modules-data`)
        .pipe(
            map((response=>{
                const data = []
                const projectEnumKeys = Object.keys(IacProjectTypeEnum);
                for(let type in response){
                    const projects = response[type as keyof IacProjects]
                    if(projectEnumKeys.includes(type)){
                        data.push(...projects.checked.map((value) => {return {...value, type: type}}))
                        data.push(...projects.unchecked.map((value) => {return {...value, type: type}}))
                        data.push(...projects.ignored.map((value) => {return {...value, type: type}}))
                    }
                }
                return data
            }))
        )
    }
}
