<div class="menu">
  <img src="/assets/compliance.png" alt="" width="283px" height="78px" />
  <div class="nav">
    <ng-container *ngFor="let link of links">
      <div class="nav-item">
        <a #rla="routerLinkActive" [routerLink]="[link.path]" routerLinkActive="active">
          <img class="logo" src="/assets/icons/{{link.icon}}{{rla.isActive | iconSuffixName}}.svg">
          {{ link.label }}</a
        >
        <ng-container *ngIf="link.children && rla.isActive">
            <ng-container *ngFor="let children of link.children">
                <a #rla="routerLinkActive" [routerLink]="[children.path]" routerLinkActive="{{children.children !== [] ? '':'active'}}" class="child">
                  <img class="logo" src="/assets/icons/{{link.icon}}.svg">
                    {{ children.label }}
                </a>
                <ng-container *ngIf="children.children && rla.isActive">
                    <ng-container *ngFor="let child of children.children">
                        <a #rla="routerLinkActive" [routerLink]="[child.path]" routerLinkActive="active" class="child-2">
                          <img class="logo" src="/assets/icons/{{link.icon}}{{rla.isActive | iconSuffixName}}.svg">
                            {{ child.label }}
                        </a>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <app-button
    width="17rem"
    [label]='"SIDENAV.LOGOUT.LABEL" | translate'
    [description]='"SIDENAV.LOGOUT.DESCRIPTION" | translate'
    img="./assets/icons/power_settings.svg"
    [callbackFunction]="logOut"
  ></app-button>
</div>
<div class="content">
  <ng-content></ng-content>
</div>
<div class="profile" *ngIf="profile | async as user">
  <img *ngIf="user!.image != null" class="profile-img" [src]="user!.image" alt="">
  <img *ngIf="user!.image == null" class="profile-img" src="./assets/icons/unknown_user.svg" alt="">
  <span class="profile-identity">{{user!.lastName}} {{user!.firstName}}</span>
  <span *ngIf="user!.companyFunction !== 'None'" class="profile-companyFunction">{{user!.companyFunction}}</span>
    <div class="challenges-section" *ngIf="isChallengesLoaded && (challenges$ | async) && user!.challenger" (click)="goChallenges()" title='{{ "CHALLENGES.SEE_MORE.DESCRIPTION" | translate }}'>
        <span class="challenges-title">{{"CHALLENGES.TITLE" | translate}}</span>
        <div class="challenges-list">
            <ng-container *ngFor="let challenge of (challenges$ | async);">
              <section class="challenges-item">
                <div class="item">
                  <span class="item-description">{{"CHALLENGES." + challenge.challenge_type + "_" + challenge.challenge_target | translate}}</span>
                  <span class="item-actions">{{"CHALLENGES.ACTIONS_COUNT" | translate: {actions_current: challenge.challenge_current, actions_goal: challenge.challenge_goal} }}</span>
                </div>
                <div class="item-progress">
                  <circle-progress [percent]="numberAttribute(challenge.challenge_ratio, 0)"
                                   [outerStrokeColor]="numberAttribute(challenge.challenge_ratio, 0) | challengeColor"
                                   [unitsFontSize]="'15'"
                                   [unitsFontWeight]="'450'"
                                   [titleFontSize]="'15'"
                                   [titleFontWeight]="'450'">
                  </circle-progress>
                </div>
              </section>
            </ng-container>
        </div>
        <div class="congratulations" *ngIf="(challenges$ | async)!.length == 0" title='{{ "CHALLENGES.SEE_MORE.DESCRIPTION" | translate }}'>
          <img class="image" src="assets/img/congratulations.png"/>
          <div class="title">{{ "CHALLENGES.CONGRATULATIONS.TITLE" | translate }}</div>
          <div class="message">{{ "CHALLENGES.CONGRATULATIONS.MESSAGE" | translate }}</div>
        </div>

        <app-button
          class="button"
          width="10rem"
          backgroundColor="var(--color_button_default)"
          [label]='"CHALLENGES.SEE_MORE.LABEL" | translate'
          [description]='"CHALLENGES.SEE_MORE.DESCRIPTION" | translate'
          [callbackFunction]="goChallenges"
        ></app-button>

    </div>
    <div class="help-section">
      <img width="60" height="60" src="./assets/icons/question.svg">
      <span class="title">{{"SIDENAV.HELP" | translate}}</span>
      <app-button
        class="button"
        width="10rem"
        backgroundColor="black"
        [label]='"SIDENAV.GO_USER_GUIDE.LABEL" | translate'
        [description]='"SIDENAV.GO_USER_GUIDE.DESCRIPTION" | translate'
        [callbackFunction]="goUserGuide"
      ></app-button>
    </div>
</div>
