import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken, Store } from "@ngxs/store";
import { ImpervaResourcesService } from "./imperva-resources.service";
import {
    ClearImpervaResource,
    LoadImpervaResources,
    LoadDataTableImpervaResources,
    SetImpervaResource,
    UpdateCountersImpervaResources,
    LoadImpervaCharts,
    LoadImpervaChartsFilteredData
} from "./imperva-resources.actions";
import { tap } from "rxjs";
import { ImpervaResourcesStateModel, ImpervaResource } from "./imperva-resources.model";
import { FiltersState } from "../filters/filters.state";
import { CompliantRatioService } from "src/app/shared/service/compliant-ratio.service";
import { ImpervaResourceTypeEnum } from "src/app/shared/enum/imperva-resource-type.enum";
import { ChartComplianceRatioItem } from "src/app/shared/component/chart-compliance-ratio/chart-compliance-ratio.component";
import { ChartComplianceTotalItem } from "src/app/shared/component/chart-compliance-total/chart-compliance-total.component";
import { ChartVersusItem } from "src/app/shared/component/chart-versus/chart-versus.component";
import { DateService } from "src/app/shared/service/date.service";
import { ImpervaResourceTypeLabelPipe } from "src/app/shared/pipe/imperva-resource-type-label.pipe";
import { ColorEnum } from "src/app/shared/enum/color.enum";
import { ItemTypeEnum } from "src/app/shared/enum/item-type.enum";

const IAC_TOKEN: StateToken<ImpervaResourcesStateModel> = new StateToken('impervaResources');

const ImpervaResourcesStateModelDefaults: ImpervaResourcesStateModel = {
    resources: [],
    compliantRatio: '0%',
    totalItems: '0',
    compliantItems: '0',
    notCompliantItems: '0',
    datatable: [],
    selectedResource: {
        account: '',
        age: 0,
        id: '',
        tfstates: [],
        projects: [],
        issues: [],
        name: '',
        source: '',
        state: 'ignored',
        type: ''
    },
    chartsData: {
        counters: {}
    },
    filteredComplianceRatioChartData: {
        values: []
    },
    filteredComplianceTotalChartData: {
        values: []
    },
    filteredVersusChartData: {
        values: []
    }
}

@State<ImpervaResourcesStateModel>({
    name: IAC_TOKEN,
    defaults: ImpervaResourcesStateModelDefaults
})

@Injectable()
export class ImpervaResourcesState {

    constructor(
        private impervaResourcesService: ImpervaResourcesService,
        private store: Store,
        private dateService: DateService,
        private impervaResourceTypeLabelPipe: ImpervaResourceTypeLabelPipe
    ) {
    }

    @Selector()
    static getResources(state: ImpervaResourcesStateModel) {
        return state.resources
    }

    @Selector()
    static getCompliantRatio(state: ImpervaResourcesStateModel) {
        return state.compliantRatio
    }

    @Selector()
    static getTotalItems(state: ImpervaResourcesStateModel) {
        return state.totalItems
    }

    @Selector()
    static getCompliantItems(state: ImpervaResourcesStateModel) {
        return state.compliantItems
    }

    @Selector()
    static getNotCompliantItems(state: ImpervaResourcesStateModel) {
        return state.notCompliantItems
    }

    @Selector()
    static getDatatable(state: ImpervaResourcesStateModel) {
        return state.datatable
    }

    @Selector()
    static getSelectedResource(state: ImpervaResourcesStateModel) {
        return state.selectedResource
    }

    @Selector()
    static getFilteredComplianceRatioChartData(state: ImpervaResourcesStateModel) {
        return state.filteredComplianceRatioChartData
    }

    @Selector()
    static getFilteredComplianceTotalChartData(state: ImpervaResourcesStateModel) {
        return state.filteredComplianceTotalChartData
    }

    @Selector()
    static getFilteredVersusChartData(state: ImpervaResourcesStateModel) {
        return state.filteredVersusChartData
    }

    @Action(LoadImpervaResources)
    loadImpervaResource(ctx: StateContext<ImpervaResourcesStateModel>) {
        return this.impervaResourcesService.loadImpervaResources()
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        resources: value
                    })
                }),
            )
    }

    @Action(LoadDataTableImpervaResources)
    loadDataTableImpervaResources(ctx: StateContext<ImpervaResourcesStateModel>) {
        const state = ctx.getState()
        let datatable = state.resources

        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.IMPERVA_RESOURCES]]
        filters.forEach(filter => {
            if (filter.key == 'issuesCount') {
                datatable = datatable.filter((resource) => resource['issues'].length.toString() === filter.value)
            } else if (filter.key == 'issuesType') {
                datatable = datatable.filter((resource) => {
                    let issuesTypeFound = false
                    resource.issues.forEach(issue => {
                        issuesTypeFound = issuesTypeFound || filter.value.includes(issue.issue_type)
                    })
                    return issuesTypeFound
                })
            } else if (filter.key == 'age') {
                let unknownAge = 99999
                let filterAge: number = unknownAge
                if (filter.value == 'today') {
                    filterAge = 0
                } else if (filter.value.startsWith('last_')) {
                    filterAge = filter.value.substring(5, 7)
                }
                datatable = datatable.filter((resource) => {
                    let ageInRange = false
                    if (filterAge == unknownAge) {
                        ageInRange = resource.age == filterAge
                    } else {
                        ageInRange = resource.age <= filterAge
                    }
                    return ageInRange
                })
            } else {
                if (Array.isArray(filter.value)) {
                    datatable = datatable.filter((resource) => filter.value.includes(resource[filter.key as keyof ImpervaResource]))
                } else {
                    datatable = datatable.filter((resource) => resource[filter.key as keyof ImpervaResource] === filter.value)
                }
            }
        });
        ctx.patchState({
            datatable: datatable
        })
    }

    @Action(UpdateCountersImpervaResources)
    updateCountersImpervaResources(ctx: StateContext<ImpervaResourcesStateModel>) {
        const state = ctx.getState()
        let totalItemsCounter = 0
        let compliantItemsCounter = 0
        let notCompliantItemsCounter = 0
        state.datatable.forEach(resource => {
            if (resource.state == 'compliant') {
                totalItemsCounter++;
                compliantItemsCounter++;
            } else if (resource.state == 'not_compliant') {
                totalItemsCounter++;
                notCompliantItemsCounter++;
            }
        });
        ctx.patchState({
            compliantRatio: CompliantRatioService.calculateCompliantRatio(compliantItemsCounter, notCompliantItemsCounter),
            totalItems: '' + totalItemsCounter,
            compliantItems: '' + compliantItemsCounter,
            notCompliantItems: '' + notCompliantItemsCounter
        })
    }

    @Action(ClearImpervaResource)
    clearImpervaResource(ctx: StateContext<ImpervaResourcesStateModel>) {
        return ctx.patchState({
            selectedResource: ImpervaResourcesStateModelDefaults.selectedResource
        })
    }

    @Action(SetImpervaResource)
    setImpervaResource(ctx: StateContext<ImpervaResourcesStateModel>, payload: any) {
        return this.impervaResourcesService.loadImpervaResourceDetails(payload.payload)
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        selectedResource: value
                    })
                }),
            )
    }

    @Action(LoadImpervaCharts)
    loadImpervaCharts(ctx: StateContext<ImpervaResourcesStateModel>) {
        return this.impervaResourcesService.loadImpervaCharts()
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        chartsData: value
                    })
                }),
            )
    }

    @Action(LoadImpervaChartsFilteredData)
    loadImpervaChartsFilteredData(ctx: StateContext<ImpervaResourcesStateModel>) {
        let chartsData = ctx.getState().chartsData
        let complianceRatioChartValues: ChartComplianceRatioItem[] = [];
        let complianceTotalChartValues: ChartComplianceTotalItem[] = [];
        let complianceVersusChartValues: ChartVersusItem[] = [];
        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.IMPERVA_MEASURES]]
        let types: string[] = []
        filters.forEach(filter => {
            types = filter.value
        })
        if (types.length == 0) {
            types = Object.keys(ImpervaResourceTypeEnum)
        }
        for (const dateString in chartsData.counters) {
            const dateMeasures = chartsData.counters[dateString];
            let formattedDate = this.dateService.formatLongDate(dateString)
            let compliantCount = 0
            let notCompliantCount = 0
            for (const type in dateMeasures) {
                if (types.includes(type)) {
                    const typeMeasures = dateMeasures[type];
                    compliantCount += typeMeasures.checked;
                    notCompliantCount += typeMeasures.unchecked;
                }
            }
            complianceRatioChartValues.push({
                "dateString": formattedDate,
                "date": new Date(formattedDate),
                "value": CompliantRatioService.calculateCompliantRatio(compliantCount, notCompliantCount)
            });
            complianceTotalChartValues.push({
                "dateString": formattedDate,
                "date": new Date(formattedDate),
                "compliantCount": compliantCount,
                "notCompliantCount": notCompliantCount,
                "total": compliantCount + notCompliantCount,
                "delta": Math.abs(compliantCount - notCompliantCount)
            });
        }
        let lastDate = Object.keys(chartsData.counters)[Object.keys(chartsData.counters).length - 1]
        let lastMeasure = chartsData.counters[lastDate]
        for (const type in lastMeasure) {
            if (types.includes(type)) {
                let typeMeasure = lastMeasure[type]
                let colorKey = 'charts_color_' + complianceVersusChartValues.length
                let dateData = {
                    "type": this.impervaResourceTypeLabelPipe.transform(type),
                    "count": typeMeasure.checked + typeMeasure.unchecked + typeMeasure.ignored,
                    "color": ColorEnum[colorKey as keyof typeof ColorEnum]
                };
                complianceVersusChartValues.push(dateData);
            }
        }
        return ctx.patchState({
            filteredComplianceRatioChartData: {
                values: complianceRatioChartValues
            },
            filteredComplianceTotalChartData: {
                values: complianceTotalChartValues
            },
            filteredVersusChartData: {
                values: complianceVersusChartValues
            }
        })
    }
}
