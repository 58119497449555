import {CanActivateFn, Router, createUrlTreeFromSnapshot} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "./auth.service";

export const authGuard: CanActivateFn = async() => {
  const router = inject(Router);
  try {
    const isAuthenticated = await inject(AuthService).isAuthenticated()
    return true
  } catch (error) {
    console.error(error)
    router.navigateByUrl('/login')
    return false
  }
};
