import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'iacSourceLabel'
})
export class IacSourceLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService){
  }

  transform(source: string): string {
    return this.translate.instant("IAC_SOURCES." + source.toUpperCase())
  }
}
