/**
 * To use these colors: "var(--color_{enum_key})"".
 * 
 * Example: "var(--color_green)" to use the color green
 */
export enum ColorEnum {
  // Basis
  white = 'white',
  black = 'black',
  green = 'green',
  light_green = '#f0faef',
  orange = 'orange',
  gray = '#dddddd',
  light_gray = '#eef2f5',
  dark_gray = 'darkgray',
  blue = '#7992ED',
  light_blue = '#c9eeff',
  dark_blue = 'blue',
  yellow = '#f1e18c',
  red = 'brown',
  purple = 'purple',

  // Transparent
  transparent_green = 'rgba(64, 122, 60, 0.10)',
  transparent_light_green = 'rgba(0, 255, 0, 0.10)',
  transparent_dark_green = 'rgba(0, 255, 0, 0.70)',
  transparent_light_red = 'rgba(255, 0, 0, 0.10)',
  transparent_dark_red = 'rgba(255, 0, 0, 0.70)',

  // Gradiant
  purple_gradiant = 'linear-gradient(90deg, #FECCFF 0%, #F480EF 100%)',
  orange_gradiant = 'linear-gradient(90deg, #FFC58F 0%, #FF806E 100%)',
  blue_gradiant = 'linear-gradient(90deg, #C8D9F9 0%, #7992ED 100%)',
  green_gradiant = 'linear-gradient(90deg, #D8FFA6 0%, #82D695 100%)',

  // Application
  app = 'var(--color_light_green)',

  // Compliancy
  compliant = 'green',
  not_compliant = 'orange',
  compliant_none = 'gray',

  // Source
  source_terraform = 'purple',
  source_cloud_formation = 'rgb(255,153,0)',
  source_none = 'gray',

  // Emergency
  emergency_moderade = '#1F8600',
  emergency_high = '#F17105',
  emergency_critical = 'red',
  emergency_none = '#969696',

  // Buttons
  button_default = 'var(--color_black)',
  button_background_selected = 'var(--color_green)',
  button_background_unselected = 'var(--color_gray)',
  button_background_disabled = 'var(--color_light_gray)',
  button_stripped_background_selected = 'repeating-linear-gradient(45deg, green, green 50px, black 50px, black 60px)',
  button_stripped_background_unselected = 'repeating-linear-gradient(45deg, #dddddd, #dddddd 50px, white 50px, white 60px)',
  button_stripped_background_disabled = 'repeating-linear-gradient(45deg, #eef2f5, #eef2f5 50px, white 50px, white 60px)',
  button_text_selected = 'var(--color_white)',
  button_text_unselected = 'var(--color_black)',
  button_text_disabled = 'var(--color_dark_gray)',
  button_validate = 'var(--color_green)',
  button_cancel = 'var(--color_red)',
  button_save = 'var(--color_green)',
  button_new = 'var(--color_blue)',
  button_delete = 'var(--color_red)',
  button_check_all = 'var(--color_black)',
  button_uncheck_all = 'var(--color_black)',

  // Sidenav
  sidenav_hover = 'var(--color_gray)',
  sidenav_background_selected = 'var(--color_green)',
  sidenav_text_selected = 'var(--color_white)',
  sidenav_text_unselected = 'var(--color_black)',

  // Content
  content = 'var(--color_white)',

  // Challenges
  challenges = 'var(--color_white)',
  challenges_items = 'rgba(250, 250, 250, 1)',
  challenges_actions = 'rgba(27, 28, 30, 0.7)',
  challenges_separator = 'rgba(238, 242, 245, 1)',
  challenges_ratio_low = '#ff816e',
  challenges_ratio_medium = '#ffd000',
  challenges_ratio_high = '#2fa220',

  // Profile
  profile_company_function = 'var(--color_yellow)',

  // Help
  help = 'var(--color_transparent_green)',

  // Home
  home_carousel = 'var(--color_light_blue)',
  home_objective = 'var(--color_light_gray)',

  // Border
  border_hover = 'var(--color_black)',
  border_selected = 'var(--color_green)',
  border_unselected = 'var(--color_white)',

  // Link
  link = 'var(--color_dark_blue)',

  // Gap
  gap_up_background = 'var(--color_transparent_dark_red)',
  gap_up_text = 'var(--color_yellow)',
  gap_down_background = 'var(--color_transparent_dark_green)',
  gap_down_text = 'var(--color_green)',

  // Filter
  filter_background = 'rgb(96, 125, 139)',
  filter_text = 'var(--color_white)',
  filter_none = 'var(--color_gray)',

  // Search
  search = '#ccc',

  // List selection
  list_selection = 'var(--color_light_gray)',

  // Details
  details_info_title = 'var(--color_dark_gray)',

  // On/off
  state_on = 'var(--color_green)',
  state_off = 'var(--color_orange)',
  state_on_off_none = 'var(--color_dark_gray)',

  // Charts
  charts_default = '#1b7cb3',
  charts_color_0 = '#5e4fa2',
  charts_color_1 = '#3288bd',
  charts_color_2 = '#66c2a5',
  charts_color_3 = '#abdda4',
  charts_color_4 = '#e6f598',
  charts_color_5 = '#fee08b',
  charts_color_6 = '#fdae61',
  charts_color_7 = '#f46d43',
  charts_color_8 = '#d53e4f',
  charts_color_9 = '#9e0142'
}
