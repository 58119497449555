import { Pipe, PipeTransform } from '@angular/core';
import { ColorEnum } from '../enum/color.enum';

@Pipe({
  name: 'iacSourceColor'
})
export class IacSourceColorPipe implements PipeTransform {

  transform(source: string): string {
    switch (source) {
      case 'terraform':
      case 'Terraform':
        return ColorEnum.source_terraform;
      case 'cloud_formation':
      case 'CloudFormation':
        return ColorEnum.source_cloud_formation;
      case 'None':
        return ColorEnum.source_none;
      default:
        return ''
    }
  }
}
