import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImpervaResourceTypeEnum } from '../enum/imperva-resource-type.enum';

@Pipe({
  name: 'impervaResourceTypeLabel'
})
export class ImpervaResourceTypeLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService){

  }

  transform(type: string): string {
    const impervaEnumKeys = Object.keys(ImpervaResourceTypeEnum);
    if(impervaEnumKeys.includes(type)){
      return this.translate.instant("IMPERVA_RESOURCES.TYPE." + type.toUpperCase().replaceAll("-", "_"))
    }
    return type
  }
}
