export type ChallengeAction = {
    emergency: string,
    project_name: string,
    project_number: string,
    project_id: string,
    branch_name: string,
    account_id: string,
    measure_id: string,
    resource_id: string,
    resource_name: string,
    issue_description: string,
    issue_type: string
}

export type Challenge = {
    challenger: boolean,
    challenge_ratio: string,
    challenge_type: string,
    challenge_target: string,
    challenge_goal: string
    challenge_current: string,
    email_address: string,
    actions_count: string,
    actions: ChallengeAction[]
}

export interface IChallengesResp {
    ["cicd-challenges"]: Challenge[],
    ["iac-challenges"]: Challenge[],
    ["finops-aws-challenges"]: Challenge[],
    "challenge": Challenge
}

export class ChallengesStateModel {
    constructor(
        public cicd_challenges: Challenge[],
        public iac_challenges: Challenge[],
        public finops_aws_challenges: Challenge[],
        public selected_challenge: Challenge | null,
        public selected_project_id: string | null) { }
}