import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, mergeMap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken()
    .pipe(
      mergeMap((token)=>{
        const authRequest = request.clone({
          setHeaders: {
            Authorization: `${token.getIdToken().getJwtToken()}`
          }
        })
        return next.handle(authRequest);
      }),
      catchError(err =>{
        console.error(err)
        return next.handle(request)
      })
    )
  }
}
