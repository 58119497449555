import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  constructor() {
  }

  transform(value: string, maxLength: number = 40): string {
    return value.length <= maxLength ?
      value :
      "".concat(value.slice(0, maxLength), "...")
  }

}
