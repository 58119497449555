import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type Info = {
  title: string,
  value: string
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {

  @Input()
  background = 'linear-gradient(90deg, #D8FFA6 0%, #82D695 100%)'

  @Input()
  title = ''

  @Input()
  img = ''

  @Input()
  value = ''

  @Input()
  actionDescription = ''

  @Input()
  callbackFunction!: () => void
}
