import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Challenge, ChallengesStateModel } from "./challenges.model";
import { Injectable } from "@angular/core";
import { ChallengeService } from "./challenge.service";
import { LoadChallenges, SetChallenge, SetChallengeProject } from "./challenge.actions";
import { map, tap } from "rxjs";

export const CHALLENGES_TOKEN = 'challenges'
export const CHALLENGE_TOKEN = 'challenge'

const ChallengesStateModelDefault: ChallengesStateModel = {
    iac_challenges: [],
    cicd_challenges: [],
    finops_aws_challenges: [],
    selected_challenge: null,
    selected_project_id: null
}

@State<ChallengesStateModel>({
    name: CHALLENGES_TOKEN,
    defaults: ChallengesStateModelDefault
})
@Injectable()
export class ChallengesState {
    constructor(private challengeService: ChallengeService) {
    }

    @Selector()
    static getAllChallenges(state: ChallengesStateModel): Challenge[] {
        return [...state.iac_challenges, ...state.cicd_challenges, ...state.finops_aws_challenges]
    }

    @Action(LoadChallenges)
    loadChallenges(ctx: StateContext<ChallengesStateModel>) {
        return this.challengeService.getChallenges()
            .pipe(
                map(value => new ChallengesStateModel(value["cicd-challenges"], value["iac-challenges"], value["finops-aws-challenges"], null, null)),
                tap(value => ctx.patchState(value))
            )
    }

    @Selector()
    static getSelectedChallenge(state: ChallengesStateModel) {
        return state.selected_challenge
    }

    @Action(SetChallenge)
    setChallenge(ctx: StateContext<ChallengesStateModel>, payload: any) {
        return ctx.patchState(
            {
                selected_challenge: payload.payload
            })
    }

    @Selector()
    static getSelectedProjectActions(state: ChallengesStateModel) {
        let actions: any[] = []
        state.selected_challenge?.actions.forEach(action => {
            if (action.project_id == state.selected_project_id) {
                actions.push(action)
            }
        });
        return actions
    }

    @Action(SetChallengeProject)
    setChallengeProject(ctx: StateContext<ChallengesStateModel>, payload: any) {
        return ctx.patchState(
            {
                selected_project_id: payload.payload
            })
    }
}
