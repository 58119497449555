import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CicdProjectTypeEnum } from '../enum/cicd-project-type.enum';

@Pipe({
  name: 'cicdProjectTypeLabel'
})
export class CicdProjectTypeLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService){

  }

  transform(type: string): string {
    const projectEnumKeys = Object.keys(CicdProjectTypeEnum);
    if(projectEnumKeys.includes(type)){
      return this.translate.instant("CICD_PROJECTS.TYPE." + type.toUpperCase().replaceAll("-", "_"))
    }
    return type
  }
}
