import { Injectable } from "@angular/core";
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'    
})
export class LoadingService {
    loadingRouterBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
    
    loadingMap: Map<string, boolean> = new Map<string, boolean>();
    loadingHttpRequestBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)

    constructor(private router: Router) {}

    routingLoader() {
    this.router.events.subscribe((event: Event) => {
        switch (true) {
            case event instanceof NavigationStart:
                this.loadingRouterBehaviorSubject.next(true);
                break;
    
            case event instanceof NavigationCancel:
            case event instanceof NavigationError:
            case event instanceof NavigationEnd:
                this.loadingRouterBehaviorSubject.next(false);
                break;
            default:
                break;
        }
        })
    }

    setLoading(loading: boolean, url: string): void {
        if (!url) throw new Error('The request URL must be provided to LoadingService.setLoading function');

        if(loading === true) {
            this.loadingMap.set(url, loading);
            this.loadingHttpRequestBehaviorSubject.next(true);
        } else if (loading === false && this.loadingMap.has(url)) {
            this.loadingMap.delete(url);
        }

        if (this.loadingMap.size === 0) this.loadingHttpRequestBehaviorSubject.next(false); 
    }

}