import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {

  @Input()
  filterKey = ''

  @Input()
  filterLabel = ''

  @Input()
  filterViewValue = ''

  @Input()
  callbackFunction!: (filterKey: string) => void
}
