import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GcpResourceTypeEnum } from '../enum/gcp-resource-type.enum';

@Pipe({
  name: 'gcpResourceTypeLabel'
})
export class GcpResourceTypeLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService){

  }

  transform(type: string): string {
    const gcpEnumKeys = Object.keys(GcpResourceTypeEnum);
    if(gcpEnumKeys.includes(type)){
      return this.translate.instant("GCP_RESOURCES.TYPE." + type.toUpperCase().replaceAll("-", "_"))
    }
    return type
  }
}
