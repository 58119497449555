import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ConfigService } from "src/app/core/config.service";
import { GcpChartsData, GcpResourceDetails, GcpResources } from "./gcp-resources.model";
import {GcpResourceTypeEnum} from "../../shared/enum/gcp-resource-type.enum";

@Injectable({
    providedIn: 'root'
})
export class GcpResourcesService {

    organizationId = "246842700424"

    constructor(private httpClient: HttpClient, private configService: ConfigService){

    }

    loadGcpResources(){
        return this.httpClient.get<GcpResources>(`${this.configService.config.apiUrl}/list-gcp-resources-data?organization=${this.organizationId}`)
        .pipe(
            map((response=>{
                const data = []
                const gcpEnumKeys = Object.keys(GcpResourceTypeEnum);
                for(let type in response){
                    const resources = response[type as keyof GcpResources]
                    if(gcpEnumKeys.includes(type)){
                        data.push(...resources.checked.map((value) => {return {...value, type: type}}))
                        data.push(...resources.unchecked.map((value) => {return {...value, type: type}}))
                        data.push(...resources.ignored.map((value) => {return {...value, type: type}}))
                    }
                }
                return data
            }))
        )
    }

    loadGcpResourceDetails(resourceId: string){
        return this.httpClient.get<GcpResourceDetails>(`${this.configService.config.apiUrl}/get-gcp-resource-details?organization=${this.organizationId}&resource_id=${resourceId}`)
    }

    loadGcpCharts(){
        return this.httpClient.get<GcpChartsData>(`${this.configService.config.apiUrl}/list-gcp-charts-data?organization=${this.organizationId}`)
    }
}
