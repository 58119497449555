import { Injectable } from "@angular/core";
import {Action, Selector, State, StateContext, StateToken} from "@ngxs/store";
import { TfModulesService } from "./tfmodules.service";
import {
  ClearTfModuleProject,
  LoadTfModulesData,
  SetTfModuleProjectByName,
  SetTfModuleProjectByPath,
  SetTfModulesFactorisationMode
} from "./tfmodules.actions";
import { tap } from "rxjs";
import { TfModulesStateModel } from "./tfmodules.model";
import { FactorisationModeEnum } from "src/app/shared/enum/factorisation-mode.enum";

const TFMODULES_TOKEN: StateToken<TfModulesStateModel> = new StateToken('tfModules');
const TfModulesStateModelDefault: TfModulesStateModel = {
  projects: [],
  factorisationMode: FactorisationModeEnum.USAGE,
  selectedProject: {
    name: "",
    url: "",
    age: 0,
    full_path: "",
    tfmodules: [],
    archived: false,
    type: ""
  }
}

@State<TfModulesStateModel>({
    name: TFMODULES_TOKEN,
    defaults: TfModulesStateModelDefault
})
@Injectable()
export class TfModulesState {

    constructor(
        private tfModulesService: TfModulesService
    ) {
    }

    @Selector()
    static getProjects(state: TfModulesStateModel) {
        return state.projects
    }

    @Selector()
    static getFactorisationMode(state: TfModulesStateModel) {
        return state.factorisationMode
    }

    @Selector()
    static getSelectedProject(state: TfModulesStateModel) {
        return state.selectedProject
    }

    @Action(LoadTfModulesData)
    loadTfModulesData(ctx: StateContext<TfModulesStateModel>) {
        return this.tfModulesService.loadTfModulesData()
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        projects: value
                    })
                }),
            )
    }

    @Action(SetTfModulesFactorisationMode)
    setTfModulesFactorisationMode(ctx: StateContext<TfModulesStateModel>, payload: any){
        return ctx.patchState({
            factorisationMode: payload.payload
        })
    }

    @Action(ClearTfModuleProject)
    clearTfModuleProject(ctx: StateContext<TfModulesStateModel>){
        return ctx.patchState({
            selectedProject: TfModulesStateModelDefault.selectedProject
        })
    }

    @Action(SetTfModuleProjectByPath)
    setTfModuleProject(ctx: StateContext<TfModulesStateModel>, payload: any){
        const state = ctx.getState()
        state.projects.forEach(project => {
            if (project.full_path == payload.payload) {
                ctx.patchState({
                    selectedProject: project
                })
            }
        })
    }
    @Action(SetTfModuleProjectByName, { cancelUncompleted: true })
    setSpecificTfModuleProject(ctx: StateContext<TfModulesStateModel>, action: SetTfModuleProjectByName){
        const state = ctx.getState().projects
        let project = state.find((element) => element.name == action.name)
        if(project != undefined) {
            ctx.patchState({
                selectedProject: project
            })
        }
    }
}
