import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/core/config.service";
import { FinOpsAwsServicesDayMeasures } from "./finops-aws-services.model";
import { DateService, HistoricalDate } from "src/app/shared/service/date.service";
import { FinOpsContextConfig } from "../finops-context/finops-context.model";
import { DateGranularityEnum } from "src/app/shared/enum/date-granularity.enum";
import { forkJoin } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FinOpsAwsServicesService {

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigService,
        private dateService: DateService
    ) {
    }

    loadFinOpsAwsServicesHistoricalData(config: FinOpsContextConfig, accountId: string) {
        let httpCalls = []
        let historicalDates: HistoricalDate[] = this.dateService.getHistoricalDates(config, 3)
        for (let historicalDate of historicalDates) {
            httpCalls.push(this.httpClient.get<FinOpsAwsServicesDayMeasures>(`${this.configService.config.apiUrl}/list-finops-aws-costs-services-data?previous_date=${historicalDate.previousDate}&date=${historicalDate.date}&granularity=${DateGranularityEnum[config.granularity]}&account=${accountId}`))
        }
        return forkJoin(httpCalls)
    }

    loadFinOpsAwsServicesChartsData(config: FinOpsContextConfig, accountId: string) {
        let httpCalls = []
        let historicalDates: HistoricalDate[] = this.dateService.getHistoricalDates(config, config.depth)
        for (let historicalDate of historicalDates) {
            httpCalls.push(this.httpClient.get<FinOpsAwsServicesDayMeasures>(`${this.configService.config.apiUrl}/list-finops-aws-costs-services-charts?date=${historicalDate.date}&account=${accountId}`))
        }
        return forkJoin(httpCalls)
    }
}
