import { Injectable } from "@angular/core";
import {Action, Selector, State, StateContext, StateToken} from "@ngxs/store";
import { CicdFactorisationsService } from "./cicd-factorisations.service";
import {
  ClearCicdFactorisationsProject,
  LoadCicdFactorisationsData,
  SetCicdFactorisationsProject,
  SetCicdFactorisationMode
} from "./cicd-factorisations.actions";
import { tap } from "rxjs";
import { CicdFactorisationsStateModel } from "./cicd-factorisations.model";
import { FactorisationModeEnum } from "src/app/shared/enum/factorisation-mode.enum";

const CICD_FACTORISATIPONS_TOKEN: StateToken<CicdFactorisationsStateModel> = new StateToken('cicdFactorisations');

const CicdFactorisationsStateModelDefault: CicdFactorisationsStateModel = {
  projects: [],
  factorisationMode: FactorisationModeEnum.USAGE,
  selectedProject: {
    id: "",
    name: "",
    url: "",
    age: 0,
    state: 'ignored',
    full_path: "",
    "cicd-docker-images": [],
    "cicd-libraries": [],
    "cicd-templates": [],
    docker_images_repos: [],
    archived: false,
    type: ""
  }
}

@State<CicdFactorisationsStateModel>({
    name: CICD_FACTORISATIPONS_TOKEN,
    defaults: CicdFactorisationsStateModelDefault
})
@Injectable()
export class CicdFactorisationsState {

    constructor(
        private cicdFactorisationsService: CicdFactorisationsService
    ) {
    }

    @Selector()
    static getProjects(state: CicdFactorisationsStateModel) {
        return state.projects
    }

    @Selector()
    static getFactorisationMode(state: CicdFactorisationsStateModel) {
        return state.factorisationMode
    }

    @Selector()
    static getSelectedProject(state: CicdFactorisationsStateModel) {
        return state.selectedProject
    }

    @Action(LoadCicdFactorisationsData)
    loadCicdFactorisationsData(ctx: StateContext<CicdFactorisationsStateModel>) {
        return this.cicdFactorisationsService.loadCicdFactorisationsData()
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        projects: value
                    })
                }),
            )
    }

    @Action(SetCicdFactorisationMode)
    setCicdFactorisationMode(ctx: StateContext<CicdFactorisationsStateModel>, payload: any){
        return ctx.patchState({
            factorisationMode: payload.payload
        })
    }

    @Action(ClearCicdFactorisationsProject)
    clearCicdFactorisationsProject(ctx: StateContext<CicdFactorisationsStateModel>){
        return ctx.patchState({
            selectedProject: CicdFactorisationsStateModelDefault.selectedProject
        })
    }

    @Action(SetCicdFactorisationsProject)
    setCicdFactorisationsProject(ctx: StateContext<CicdFactorisationsStateModel>, payload: any){
        const state = ctx.getState()
        state.projects.forEach(project => {
            if (project.full_path == payload.payload) {
                ctx.patchState({
                    selectedProject: project
                })
            }
        })
    }
}
