import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ConfigService } from "src/app/core/config.service";
import { CicdProjectDetails, CicdProjects, CicdProjectsChartsData} from "./cicd-projects.model";
import { CicdProjectTypeEnum } from "../../shared/enum/cicd-project-type.enum";

@Injectable({
    providedIn: 'root'
})
export class CicdProjectsService {

    constructor(private httpClient: HttpClient, private configService: ConfigService){

    }

    loadCicdGitlabProjects(){
        return this.httpClient.get<CicdProjects>(`${this.configService.config.apiUrl}/list-cicd-gitlab-projects-data`)
        .pipe(
            map((response=>{
                const data = []
                const projectEnumKeys = Object.keys(CicdProjectTypeEnum);
                for(let type in response){
                    const projects = response[type as keyof CicdProjects]
                    if(projectEnumKeys.includes(type)){
                        data.push(...projects.checked.map((value) => {return {...value, type: type, allBranches: value.checked.concat(value.unchecked)}}))
                        data.push(...projects.unchecked.map((value) => {return {...value, type: type, allBranches: value.checked.concat(value.unchecked)}}))
                        data.push(...projects.ignored.map((value) => {return {...value, type: type, allBranches: value.checked.concat(value.unchecked)}}))
                    }
                }
                return data
            }))
        )
    }

    loadCicdGitlabProjectDetails(projectId: string){
        return this.httpClient.get<CicdProjectDetails>(`${this.configService.config.apiUrl}/get-cicd-gitlab-project-details?project_id=${projectId}`)
    }

    loadCicdGitlabProjectsCharts(){
        return this.httpClient.get<CicdProjectsChartsData>(`${this.configService.config.apiUrl}/list-cicd-gitlab-charts-data`)
    }
}
