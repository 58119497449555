import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntraIdTenantEnum } from '../enum/entra-id-tenant.enum';

@Pipe({
  name: 'entraIdTenantLabel'
})
export class EntraIdTenantLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService) {

  }

  transform(tenantKey: string): string {
    const entraIdEnumKeys = Object.keys(EntraIdTenantEnum);
    if (entraIdEnumKeys.includes(tenantKey)) {
      return this.translate.instant("ENTRA_ID_RESOURCES.TENANT_LABEL." + tenantKey.toUpperCase())
    }
    return tenantKey
  }
}
