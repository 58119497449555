import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ConfigService } from "src/app/core/config.service";
import { IacProjectsChartsData, IacProjectDetails, IacProjects} from "./iac-projects.model";
import {IacProjectTypeEnum} from "../../shared/enum/iac-project-type.enum";

@Injectable({
    providedIn: 'root'
})
export class IacProjectsService {

    constructor(private httpClient: HttpClient, private configService: ConfigService){

    }

    loadIacGitlabProjects(){
        return this.httpClient.get<IacProjects>(`${this.configService.config.apiUrl}/list-iac-gitlab-projects-data`)
        .pipe(
            map((response=>{
                const data = []
                const projectEnumKeys = Object.keys(IacProjectTypeEnum);
                for(let type in response){
                    const projects = response[type as keyof IacProjects]
                    if(projectEnumKeys.includes(type)){
                        data.push(...projects.checked.map((value) => {return {...value, type: type, allBadges: value.checked.concat(value.unchecked).concat(value.ignored)}}))
                        data.push(...projects.unchecked.map((value) => {return {...value, type: type, allBadges: value.checked.concat(value.unchecked).concat(value.ignored)}}))
                        data.push(...projects.ignored.map((value) => {return {...value, type: type, allBadges: value.checked.concat(value.unchecked).concat(value.ignored)}}))
                    }
                }
                return data
            }))
        )
    }

    loadIacGitlabProjectDetails(projectId: string){
        return this.httpClient.get<IacProjectDetails>(`${this.configService.config.apiUrl}/get-iac-gitlab-project-details?project_id=${projectId}`)
        .pipe(
            map((response=>{
                response.allBadges = response.checked.concat(response.unchecked).concat(response.ignored)
                return response
            }))
        )
    }

    loadIacGitlabProjectsCharts(){
        return this.httpClient.get<IacProjectsChartsData>(`${this.configService.config.apiUrl}/list-iac-gitlab-charts-data`)
    }
}
